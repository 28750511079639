import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useEffect, useMemo, useState } from 'react';
import { useOnCheckoutClick } from 'utils/checkout-link';
import styles from './MultiBundleItem.theme1.module.css';
import RatingStars from './RatingStars';
import Spinner from './Spinner';
import Image from './Image';
import { useFunnelData } from 'utils/funnel-data-context';
import { usePreviewData } from 'utils/preview-data-context';
import { useTrackViewedProduct } from 'utils/klaviyo';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { missingPageOffer } from 'utils/error';
import formatCurrency from 'utils/format-currency';
import { buildCssVar } from 'utils/style-override';
import { queryTemplate as afterpayWidgetQueryTemplate } from './AfterpayWidget';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on Product': [
    'id',
    'type',
    'name',
    'heading',
    { image: ['...FilePartsWithMetadata'] },
    'text',
    'rating',
    'ctaText',
    'showSavingsPercent',
    { afterpayWidget: afterpayWidgetQueryTemplate },
    'headingColor',
    'headingBackgroundColor',
    'productNameColor',
    'textColor',
    'backgroundColor',
    'compareAtColor',
    'buttonTextColor',
    'buttonBackgroundColor',
    'buttonBorderColor',
    'buttonHoverBorderColor',
    'buttonHoverColor',
    'buttonHoverTextColor',
    'starColor',
    'starEmptyColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.object.isRequired,
  text: PropTypes.string,
  rating: PropTypes.number.isRequired,
  ctaText: PropTypes.string.isRequired,
  headingColor: PropTypes.string,
  headingBackgroundColor: PropTypes.string,
  productNameColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  compareAtColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  buttonHoverTextColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonHoverBorderColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  starColor: PropTypes.string,
  starEmptyColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  name: null,
  heading: null,
  text: undefined,
  headingColor: null,
  headingBackgroundColor: null,
  productNameColor: null,
  textColor: null,
  backgroundColor: null,
  compareAtColor: null,
  buttonTextColor: null,
  buttonBackgroundColor: null,
  buttonHoverColor: null,
  buttonHoverTextColor: null,
  buttonBorderColor: null,
  buttonHoverBorderColor: null,
  starColor: null,
  starEmptyColor: null,
  headingFont: null,
  textFont: null,
};

export default function MultiBundleItem({
  id,
  type,
  name,
  heading,
  image,
  text,
  rating,
  ctaText,
  headingColor,
  headingBackgroundColor,
  productNameColor,
  textColor,
  backgroundColor,
  compareAtColor,
  buttonTextColor,
  buttonBackgroundColor,
  buttonHoverColor,
  buttonHoverTextColor,
  buttonBorderColor,
  buttonHoverBorderColor,
  starColor,
  starEmptyColor,
  headingFont,
  textFont,
}) {
  const { preview } = usePreviewData();
  useEffect(() => {
    if (type !== 'bundle') {
      console.error(
        `Incorrect Product type for MultiBundle: Products must be of type "Multi Bundle"`,
        { id, type }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { offersConfig, offerData } = useFunnelData();
  const [shouldShowActivityIndicator, setShouldShowActivityIndicator] = useState(false);

  const getCheckoutClickHandler = useOnCheckoutClick({
    setShouldShowActivityIndicator,
    shouldTrackSelectItem: true,
  });

  const { bundleConfig, bundleOffer } = useMemo(() => {
    if (offerData.mock) console.warn('Using mock offer data');
    const config = offersConfig?.find(el => el.directusProductId === id);

    let offer;
    if (offerData.mock) {
      offer = offerData.data[0];
    } else {
      offer = offerData.data.find(el => el.id === config?.id);
    }

    return { bundleConfig: config, bundleOffer: offer };
  }, [id, offersConfig, offerData]);

  // Offer/Directus Product mapping validation
  useEffect(() => {
    if (!bundleOffer) missingPageOffer(id, preview);
  }, [bundleOffer, id, preview]);

  useTrackViewedProduct(bundleOffer, bundleConfig);

  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--primary-color)')}
          ${buildCssVar(
            '--heading-background-color',
            headingBackgroundColor,
            'var(--secondary-color)'
          )}
          ${buildCssVar('--product-name-color', productNameColor, 'var(--text-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar('--compare-at-color', compareAtColor, 'var(--color-negative)')}
          ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-light)')}
          ${buildCssVar('--button-background-color', buttonBackgroundColor, 'var(--primary-color)')}
          ${buildCssVar('--button-hover-color', buttonHoverColor, 'var(--primary-color)')}
          ${buildCssVar(
            '--button-hover-text-color',
            buttonHoverTextColor,
            'var(--text-color-light)'
          )}
          ${buildCssVar(
            '--button-border-color',
            buttonBorderColor,
            buttonBackgroundColor,
            'var(--primary-color)'
          )}
          ${buildCssVar(
            '--button-hover-border-color',
            buttonHoverBorderColor,
            buttonHoverColor,
            'var(--primary-color)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div>
        {shouldShowActivityIndicator && <Spinner />}
        {heading && (
          <div className={styles.bundle_banner}>
            <h3>{heading}</h3>
          </div>
        )}
        <div className={styles.product_detail_container}>
          <div className={styles.product_image_wrapper}>
            <Image src={image} alt={name || bundleOffer?.name} placeholder="blur" />
          </div>
          <div className={styles.bundle_name_items_wrapper}>
            <h2 className={styles.product_name}>{name || bundleOffer?.name}</h2>
            {!!rating && (
              <div>
                <RatingStars
                  rating={rating}
                  starColor={starColor}
                  starEmptyColor={starEmptyColor}
                />
              </div>
            )}
            {text && (
              <div className={styles.bundle_detail_wrapper}>
                <MarkdownText text={text} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.price_button_wrapper}>
        {bundleOffer?.compareAtPrice && (
          <p className={styles.compare_price}>{formatCurrency(bundleOffer.compareAtPrice)}</p>
        )}
        <h2 className={styles.price_wrapper}>${bundleOffer?.price}</h2>
        <button
          onClick={getCheckoutClickHandler([{ offerId: bundleOffer?.id, quantity: 1 }])}
          className={styles.product_btn}
        >
          {ctaText}
        </button>
      </div>
    </div>
  );
}

MultiBundleItem.propTypes = propTypes;
MultiBundleItem.defaultProps = defaultProps;

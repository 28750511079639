import styles from './Callout.module.css';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useTextFontOverrides } from 'utils/font-override';
import Image from './Image';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on Callout': [
    'text',
    { image: ['...FilePartsWithMetadata'] },
    'imageAlt',
    'textColor',
    'backgroundColor',
    { textFont: ['family'] },
  ],
};

const propTypes = {
  text: PropTypes.string.isRequired,
  image: PropTypes.object,
  imageAlt: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  image: null,
  imageAlt: null,
  textColor: null,
  backgroundColor: null,
  textFont: null,
};

export default function Callout({
  text,
  formattedText,
  image,
  imageAlt,
  textColor,
  backgroundColor,
  textFont,
}) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {image && (
        <div className={styles.icon_wrapper}>
          <Image src={image} alt={imageAlt} />
        </div>
      )}
      <div className={styles.text}>
        {!formattedText && <MarkdownText text={text} />}
        {formattedText && <div dangerouslySetInnerHTML={{ __html: formattedText }} />}
      </div>
    </div>
  );
}

Callout.propTypes = propTypes;
Callout.defaultProps = defaultProps;

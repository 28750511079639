import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import { buildCssVar } from 'utils/style-override';
import styles from './FunnelCartProduct.module.css';
import { queryTemplate as funnelCartBuyFromQueryTemplate } from './FunnelCartBuyFrom';
import { getOfferUnitPrice, parsePrice, getPercentSaved } from 'utils/price';
import { BuyFromType, BuyFromAvailability } from 'constant';
import formatCurrency from 'utils/format-currency';
import { onSelectItem } from 'utils/gtm';
import * as rudder from 'utils/rudderstack';

export const queryTemplate = {
  '... on Product': [
    'id',
    'type',
    'name',
    { image: ['...FilePartsWithMetadata'] },
    {
      buyFroms: [
        {
          item: [funnelCartBuyFromQueryTemplate],
        },
      ],
    },
    'calloutText',
    'showPrice',
    'showSavingsPercent',
    'headingColor',
    'textColor',
    'selectedBackgroundColor',
    'backgroundColor',
    'borderColor',
    'calloutTextColor',
    'calloutBackgroundColor',
  ],
};

export const productPropTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  image: PropTypes.object.isRequired,
  calloutText: PropTypes.string,
  showPrice: PropTypes.bool,
  showSavingsPercent: PropTypes.bool,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  selectedBackgroundColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  calloutTextColor: PropTypes.string,
  calloutBackgroundColor: PropTypes.string,
};

const productOfferPropTypes = {
  offer: PropTypes.shape({ name: PropTypes.string }),
};

const propTypes = {
  onClick: PropTypes.func.isRequired,
  product: PropTypes.shape({ ...productPropTypes, ...productOfferPropTypes }).isRequired,
  isSelected: PropTypes.bool,
  showProductUnitPrice: PropTypes.bool,
  hasCalloutText: PropTypes.bool,
  hasSavingsPercent: PropTypes.bool,
};

const defaultProps = {
  isSelected: false,
  showProductUnitPrice: false,
  hasCalloutText: false,
  hasSavingsPercent: false,
};

function FunnelCartProduct({
  onClick,
  product,
  isSelected,
  showProductUnitPrice,
  hasCalloutText,
  hasSavingsPercent,
}) {
  const {
    id,
    type,
    name,
    image,
    calloutText,
    showPrice,
    showSavingsPercent,
    headingColor,
    textColor,
    selectedBackgroundColor,
    backgroundColor,
    borderColor,
    calloutTextColor,
    calloutBackgroundColor,
  } = product;

  useEffect(() => {
    if (type !== 'funnelCart') {
      console.error(
        `Incorrect Product type for Funnel Cart: Products must be of type "Funnel Cart"`,
        {
          id,
          type,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(() => {
    onClick(product);
    // Quantity is reset to 1 on product select, so no quantity is considered here.
    onSelectItem(product.offer);
    rudder.onSelectItem(product.offer);
  }, [onClick, product]);

  const buttonClassNames = [styles.main, isSelected ? styles.selected : styles.unselected];

  const productPrice = useMemo(() => {
    const amazonBuyFrom = product.buyFroms.find(({ item }) => {
      return item.type === BuyFromType.AMAZON;
    })?.item;

    const parsedAmazonPrice = parsePrice(product.offer?.amazonPrice);
    const parsedVelocityPrice = parsePrice(product.offer?.price);

    const isAmazonAvailable =
      !!parsedAmazonPrice && amazonBuyFrom?.availability === BuyFromAvailability.AVAILABLE;

    const arePricesEqual = parsedAmazonPrice === parsedVelocityPrice;

    if (isAmazonAvailable && !arePricesEqual) {
      if (showProductUnitPrice) {
        const velocityUnitPrice = getOfferUnitPrice(
          product.offer?.price,
          product.offer?.storeProductQuantity
        );
        const amazonUnitPrice = getOfferUnitPrice(
          product.offer?.amazonPrice,
          product.offer?.storeProductQuantity
        );

        return `${formatCurrency(Math.min(velocityUnitPrice, amazonUnitPrice))}+ each`;
      }

      return `${formatCurrency(Math.min(parsedVelocityPrice, parsedAmazonPrice))}+`;
    }

    if (showProductUnitPrice) {
      return `${formatCurrency(
        getOfferUnitPrice(product.offer?.price, product.offer?.storeProductQuantity)
      )} each`;
    }

    return formatCurrency(parsedVelocityPrice);
  }, [
    product.buyFroms,
    showProductUnitPrice,
    product.offer?.price,
    product.offer?.storeProductQuantity,
    product.offer?.amazonPrice,
  ]);

  const percentageOff = getPercentSaved(
    product.offer?.compareAtPrice,
    product.offer?.price
  ).toFixed(0);

  // Truncate the product name if it is too long
  const productName = truncateProductName(name || product.offer?.name);

  return (
    <div className={`root ${styles.container_wrapper}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar(
            '--selected-background-color',
            selectedBackgroundColor,
            'var(--default-background-color)'
          )}
          ${buildCssVar(
            '--unselected-background-color',
            backgroundColor,
            'var(--background-color-grey)'
          )}
          ${buildCssVar('--selected-border-color', borderColor, 'var(--border-color1)')}
          ${buildCssVar('--callout-text-color', calloutTextColor, 'var(--text-color-dark)')}
          ${buildCssVar(
            '--callout-background-color',
            calloutBackgroundColor,
            'var(--default-background-color)'
          )}
        }
      `}</style>
      {hasCalloutText && <p className={styles.callout}>{calloutText}</p>}
      <button className={buttonClassNames.join(' ')} onClick={handleClick}>
        <Image
          src={image}
          alt={name || product.offer?.name}
          priority
          placeholder="blur"
          width={100}
          height={100}
        />
        <h5 className={styles.product_name}>{productName}</h5>
        {showPrice && <p className={styles.product_price}>{productPrice}</p>}
      </button>
      {hasSavingsPercent && (
        <div className={styles.saving_percent}>
          {showSavingsPercent && <p> Save {percentageOff}%</p>}
        </div>
      )}
    </div>
  );
}

function truncateProductName(productName) {
  if (productName?.length > 50) {
    return `${productName.slice(0, 50)}...`;
  }

  return productName;
}

export default FunnelCartProduct;

FunnelCartProduct.propTypes = propTypes;
FunnelCartProduct.defaultProps = defaultProps;

import { useRouter } from 'next/router';

import * as gtm from 'utils/gtm';
import * as rudder from 'utils/rudderstack';

import { useFunnelData } from './funnel-data-context';
import { buildFunnelPath } from './url';
import { buildCartQuery, getValidCartItems } from './cart';
import { useCart } from './cart-context';

export function useOnCheckoutClick({
  setShouldShowActivityIndicator,
  paymentMethod,
  shouldTrackSelectItem,
}) {
  const router = useRouter();
  const { funnelId, offerData, checkoutPath } = useFunnelData();
  const { cartDiscountCode } = useCart();

  return cartItems => {
    return e => {
      e?.preventDefault();

      // Disable checkout click for mock data
      if (cartItems[0].mock) return;

      const validItems = getValidCartItems(cartItems);

      const offersWithQuantity = validItems.map(({ offerId, quantity }) => {
        const offer = offerData.data.find(el => el.id === offerId);
        return { ...offer, quantity };
      });

      // Only fire the select item tracking event for specific situations that indicate user interaction.
      if (shouldTrackSelectItem) {
        gtm.onSelectItem(offersWithQuantity[0]);
        rudder.onSelectItem(offersWithQuantity[0]);
      }

      gtm.onBeginCheckout(offersWithQuantity, paymentMethod);
      rudder.onBeginCheckout(offersWithQuantity, paymentMethod);

      setShouldShowActivityIndicator(true);

      const query = {
        cart: buildCartQuery(validItems),
      };

      if (paymentMethod) query.vpm = paymentMethod;
      if (cartDiscountCode) query.code = cartDiscountCode;

      router.push(buildFunnelPath(checkoutPath, funnelId, query));
    };
  };
}

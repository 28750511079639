import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';
import { queryTemplate as autoplayVideoItemQueryTemplate } from './AutoplayVideoItem.theme1';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on AutoplayVideo': [
    'sectionId',
    'tagline',
    'heading',
    'text',
    {
      items: [
        {
          item: [autoplayVideoItemQueryTemplate],
        },
      ],
    },
    'headingColor',
    'textColor',
    'backgroundColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  sectionId: PropTypes.string,
  tagline: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
  AutoplayVideoItem: PropTypes.elementType.isRequired,
  styles: PropTypes.object.isRequired,
};

const defaultProps = {
  sectionId: null,
  tagline: null,
  heading: null,
  text: undefined,
  headingColor: null,
  textColor: null,
  backgroundColor: null,
  headingFont: null,
  textFont: null,
};

export default function AutoplayVideo({
  sectionId,
  tagline,
  heading,
  text,
  formattedText,
  items,
  headingColor,
  textColor,
  backgroundColor,
  headingFont,
  textFont,
  AutoplayVideoItem,
  styles,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const rootClassNames = ['root', styles.container];
  if (backgroundColor) rootClassNames.push(styles.top_bottom_padding);

  return (
    <div id={sectionId} className={rootClassNames.join(' ')}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>
      <div className={styles.three_box_section}>
        <div className={styles.process_vd}>
          <div className={styles.sec_wrapper}>
            {tagline && <div className={styles.tagline}>{tagline}</div>}
            {heading && <h1 className={styles.heading}>{heading}</h1>}
            {text && (
              <div className={styles.text}>
                {!formattedText && <MarkdownText text={text}></MarkdownText>}
                {formattedText && <div dangerouslySetInnerHTML={{ __html: formattedText }} />}
              </div>
            )}
            <div className={styles.boxes_container}>
              {items.map(({ item }, i) => (
                <AutoplayVideoItem key={i} {...item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AutoplayVideo.propTypes = propTypes;
AutoplayVideo.defaultProps = defaultProps;

// @lkuich - Couldn't we juse use the built-in toLocaleString() method? with intl for the symbol?
export default function formatCurrency(price, ignoreZeroCents, symbol) {
  const priceNumber = Number(price);
  const hasCents = priceNumber % 1 !== 0;
  const decimals = ignoreZeroCents && !hasCents ? 0 : 2;

  // Format the number with commas
  const formattedPrice = priceNumber.toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return (symbol || '$') + formattedPrice;
}

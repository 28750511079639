import styles from './ReviewStars.module.css';
import PropTypes from 'prop-types';
import { useTextFontOverrides } from 'utils/font-override';
import RatingStars from './RatingStars';
import { buildCssVar } from 'utils/style-override';

export const queryTemplate = {
  '... on ReviewStars': [
    'stars',
    'text',
    'textColor',
    'backgroundColor',
    'starColor',
    'starEmptyColor',
    { textFont: ['family'] },
  ],
};

const propTypes = {
  stars: PropTypes.number.isRequired,
  text: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  starColor: PropTypes.string,
  starEmptyColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  text: null,
  textColor: null,
  backgroundColor: null,
  starColor: null,
  starEmptyColor: null,
  textFont: null,
};

export default function ReviewStars({
  stars,
  text,
  textColor,
  backgroundColor,
  starColor,
  starEmptyColor,
  textFont,
}) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.review_stars_wrapper}>
        {!!stars && (
          <RatingStars
            className={styles.stars}
            rating={stars}
            starColor={starColor}
            starEmptyColor={starEmptyColor}
          />
        )}
        {text && (
          <div className={styles.star_text}>
            <p>{text}</p>
          </div>
        )}
      </div>
    </div>
  );
}

ReviewStars.propTypes = propTypes;
ReviewStars.defaultProps = defaultProps;

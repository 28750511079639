import { useState, useEffect } from 'react';
import random from 'lodash/random';
import styles from './PeopleLooking.module.css';
import PropTypes from 'prop-types';
import { useTextFontOverrides } from 'utils/font-override';
import Image from './Image';
import { buildCssVar } from 'utils/style-override';

export const queryTemplate = {
  '... on PeopleLooking': [
    { icon: ['...FilePartsWithMetadata'] },
    'iconGlowColor',
    'textColor',
    'backgroundColor',
    { textFont: ['family'] },
  ],
};

const propTypes = {
  icon: PropTypes.object.isRequired,
  iconGlowColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  iconGlowColor: null,
  textColor: null,
  backgroundColor: null,
  textFont: null,
};

const getRandom = () => random(15, 30);

export default function PeopleLooking({
  icon,
  iconGlowColor,
  textColor,
  backgroundColor,
  textFont,
}) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const [peopleLooking, setPeopleLooking] = useState(getRandom());
  useEffect(() => {
    const intervalId = setInterval(() => setPeopleLooking(getRandom()), 5000);
    return () => clearInterval(intervalId); // Cleanup
  }, []);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar('--icon-glow-color', iconGlowColor, 'transparent')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {icon && (
        <div className={styles.icon_container}>
          <div className={styles.icon_wrapper}>
            <Image src={icon} alt="People looking" placeholder="blur" />
          </div>
          {iconGlowColor && <div className={styles.glow_effect}></div>}
        </div>
      )}
      <div className={styles.text}>
        <span className={styles.emphasized}>Over {peopleLooking} people</span> looking at this deal
        right now
      </div>
    </div>
  );
}

PeopleLooking.propTypes = propTypes;
PeopleLooking.defaultProps = defaultProps;

import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Image from './Image';
import styles from './AmazonReview.module.css';
import { reviewPropTypes } from 'utils/prop-types';
import { FaCheckCircle } from 'react-icons/fa';
import RatingStars from './RatingStars';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on TestimonialReview': [
    'userName',
    { userImage: ['...FilePartsWithMetadata'] },
    'title',
    'text',
    'rating',
    'userNameColor',
    'headingColor',
    'textColor',
    'backgroundColor',
    'userInfoBackgroundColor',
    'verifiedColor',
    'starColor',
    'starEmptyColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  review: PropTypes.shape({ item: PropTypes.shape(reviewPropTypes) }),
};

const defaultProps = {
  review: {},
};

function AmazonReview({ review }) {
  const {
    item: {
      userName,
      userImage,
      title,
      text,
      rating,
      userNameColor,
      headingColor,
      textColor,
      backgroundColor,
      userInfoBackgroundColor,
      verifiedColor,
      starColor,
      starEmptyColor,
      headingFont,
      textFont,
    },
  } = review;

  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--user-name-color', userNameColor, 'var(--text-color-dark)')}
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar(
            '--user-info-background-color',
            userInfoBackgroundColor,
            'var(--secondary-color)'
          )}
          ${buildCssVar('--verified-color', verifiedColor, 'var(--color-positive)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.user_info}>
        {userImage && (
          <div className={styles.user_img}>
            <Image src={userImage} alt="User Image" placeholder="blur" />
          </div>
        )}
        <div className={styles.user_name}>
          <h5 className={styles.heading}>{userName}</h5>
          <div className={styles.verified}>
            <div className={styles.verified_icon}>
              <FaCheckCircle />
            </div>
            <p>VERIFIED BUYER</p>
          </div>
        </div>
      </div>
      <div className={styles.review}>
        {!!rating && (
          <div className={styles.stars_icon}>
            <RatingStars rating={rating} starColor={starColor} starEmptyColor={starEmptyColor} />
          </div>
        )}
        <h3 className={styles.heading}>{title}</h3>
        <MarkdownText text={text} />
      </div>
    </div>
  );
}

AmazonReview.prototype = propTypes;
AmazonReview.defaultProps = defaultProps;

export default AmazonReview;

import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styles from 'generic/BrandInformationModalItem.module.css';
import IconsList from 'utils/icons-list';
import { useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on BrandInformationModalItem': [
    'text',
    { icon: ['name', 'key'] },
    'iconColor',
    'textColor',
    { textFont: ['family'] },
  ],
};

const propTypes = {
  text: PropTypes.string,
  icon: PropTypes.shape({ icon: PropTypes.string }).isRequired,
  iconColor: PropTypes.string,
  textColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};
const defaultProps = {
  text: null,
  iconColor: null,
  textColor: null,
  textFont: null,
};

export default function TrustBoxesItem({ text, icon, iconColor, textColor, textFont }) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`${styles.main} root`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--icon-color', iconColor, 'var(--text-color-dark)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>
      {icon && <div className={styles.icon}>{IconsList[icon?.key]}</div>}
      {text && (
        <div className={styles.text}>
          <MarkdownText text={text} />
        </div>
      )}
    </div>
  );
}

TrustBoxesItem.propTypes = propTypes;
TrustBoxesItem.defaultProps = defaultProps;

import PropTypes from 'prop-types';
import styles from './CheckoutErrorModal.module.css';

const propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  handleViewOrderSummary: PropTypes.func.isRequired,
  isViewOrderSummaryDisabled: PropTypes.bool.isRequired,
};

const defaultProps = {
  title: null,
};

function CheckoutErrorModal({
  title,
  message,
  handleViewOrderSummary,
  isViewOrderSummaryDisabled,
}) {
  return (
    <div className={styles.modal}>
      <style jsx global>{`
        body {
          overflow: hidden;
        }
        #sticky-banner {
          z-index: 1;
        }
      `}</style>
      <div className={styles.modal_content}>
        {title && <p>{title}</p>}
        <p className={styles.invalid_msg}>{message}</p>
        <div className={styles.modal_btn}>
          <button
            className={styles.btn}
            onClick={handleViewOrderSummary}
            disabled={isViewOrderSummaryDisabled}
          >
            View order summary
          </button>
        </div>
      </div>
    </div>
  );
}

CheckoutErrorModal.propType = propTypes;
CheckoutErrorModal.defaultProps = defaultProps;

export default CheckoutErrorModal;

import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styles from 'generic/TrustBoxesItem.module.css';
import IconsList from 'utils/icons-list';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on TrustBoxesItem': [
    'heading',
    'text',
    { icon: ['name', 'key'] },
    'headingColor',
    'textColor',
    'iconColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  icon: PropTypes.shape({ icon: PropTypes.string }).isRequired,
  heading: PropTypes.string,
  text: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  iconColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};
const defaultProps = {
  heading: null,
  text: null,
  headingColor: null,
  textColor: null,
  iconColor: null,
  headingFont: null,
  textFont: null,
};

export default function TrustBoxesItem({
  icon,
  heading,
  text,
  headingColor,
  textColor,
  iconColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`${styles.container} root`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--icon-color', iconColor, 'var(--primary-dark)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>
      {icon && <div className={styles.icon}>{IconsList[icon?.key]}</div>}
      <div>
        {heading && <h1 className={styles.heading}>{heading}</h1>}
        {text && <MarkdownText className={styles.text} text={text} />}
      </div>
    </div>
  );
}

TrustBoxesItem.propTypes = propTypes;
TrustBoxesItem.defaultProps = defaultProps;

import styles from './QuantitySelectorProduct.module.css';
import PropTypes from 'prop-types';
import { buildCssVar } from 'utils/style-override';
import { useTextFontOverrides } from 'utils/font-override';
import Image from './Image';
import { useEffect } from 'react';
import { onSelectItem } from 'utils/gtm';
import * as rudder from 'utils/rudderstack';

export const queryTemplate = {
  '... on Product': [
    'id',
    'type',
    'name',
    { image: ['...FilePartsWithMetadata'] },
    'productNameColor',
    'backgroundColor',
    'borderColor',
    { textFont: ['family'] },
  ],
};

export const productPropTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  image: PropTypes.object.isRequired,
  productNameColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const productOfferPropTypes = {
  price: PropTypes.string,
  name: PropTypes.string,
  compareAtPrice: PropTypes.string,
  maxQuantity: PropTypes.number,
  image: PropTypes.string,
  imageBlurDataUrl: PropTypes.string,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  store: PropTypes.shape({
    currency: PropTypes.string,
  }),
};

const propTypes = {
  product: PropTypes.shape({
    ...productPropTypes,
    offer: PropTypes.shape(productOfferPropTypes),
  }).isRequired,
  quantity: PropTypes.number,
  onQuantityChange: PropTypes.func.isRequired,
};

const defaultProps = {
  quantity: 1,
};

function QuantitySelectorProduct({ product, quantity, onQuantityChange }) {
  const { id, type, name, image, offer, productNameColor, backgroundColor, borderColor, textFont } =
    product;

  useEffect(() => {
    if (type !== 'quantitySelector') {
      console.error(
        `Incorrect Product type for quantitySelector: Products must be of type "Quantity Selector"`,
        { id, type }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addQuantity = () => {
    onQuantityChange(offer.id, quantity + 1);
    onSelectItem({ ...product.offer, quantity: quantity + 1 });

    rudder.onSelectItem({ ...product.offer, quantity: quantity + 1 });
  };

  const subtractQuantity = () => {
    onQuantityChange(offer.id, quantity - 1);
    onSelectItem({ ...product.offer, quantity: quantity - 1 });
    rudder.onSelectItem({ ...product.offer, quantity: quantity - 1 });
  };

  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.main}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', productNameColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar('--border-color', borderColor, 'var(--border-color2)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.img_name_wrapper}>
        <div className={styles.image_container}>
          {image && (
            <Image
              src={image}
              placeholder="blur"
              alt={name || offer.name}
              width={100}
              height={100}
              sizes="100px"
            />
          )}
        </div>
        <p className={styles.product_name}>{name || offer?.name}</p>
      </div>
      <div className={styles.quantity_selector}>
        <button
          className={`${styles.quantity_btn} ${styles.subtract_btn}`}
          onClick={subtractQuantity}
          disabled={!quantity}
        >
          &ndash;
        </button>
        <p className={styles.quantity_txt}>{quantity}</p>
        <button
          className={styles.quantity_btn}
          onClick={addQuantity}
          disabled={quantity >= offer?.maxQuantity}
        >
          +
        </button>
      </div>
    </div>
  );
}

export default QuantitySelectorProduct;

QuantitySelectorProduct.propTypes = propTypes;
QuantitySelectorProduct.defaultProps = defaultProps;

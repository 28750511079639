import PropTypes from 'prop-types';
import styles from 'generic/TrustBoxes.module.css';
import TrustBoxesItem, {
  queryTemplate as trustBoxesItemQueryTemplate,
} from 'generic/TrustBoxesItem';
import { buildCssVar } from 'utils/style-override';
import { useHeadingFontOverrides } from 'utils/font-override';

export const queryTemplate = {
  '... on TrustBoxes': [
    'heading',
    {
      items: [
        {
          item: [trustBoxesItemQueryTemplate],
        },
      ],
    },
    'headingColor',
    { headingFont: ['family'] },
  ],
};

const propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape(TrustBoxesItem.propTypes),
    })
  ).isRequired,
  headingColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = { heading: null, headingColor: null, headingFont: null };

export default function TrustBoxes({ heading, items, headingColor, headingFont }) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${headingFontOverrides ?? ''}
        }
      `}</style>
      <div className={styles.divider}></div>
      {heading && <h1 className={styles.heading}>{heading}</h1>}
      <div className={styles.boxes_container}>
        {items.length &&
          items.map(({ item }, i) => {
            return <TrustBoxesItem key={i} {...item} />;
          })}
      </div>
    </div>
  );
}

TrustBoxes.propTypes = propTypes;
TrustBoxes.defaultProps = defaultProps;

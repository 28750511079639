import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa';

import styles from './ComparisonTable.module.css';
import Image from './Image';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

export const queryTemplate = {
  '... on ComparisonTableRow': [
    {
      items: [
        {
          item: [
            {
              '... on ComparisonTableRowItem': [
                'type',
                'text',
                'icon',
                { image: ['...FileParts'] },
                'imageAlt',
              ],
            },
          ],
        },
      ],
    },
  ],
};

const propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            item: PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
              icon: PropTypes.string,
              image: PropTypes.object,
              imageAlt: PropTypes.string,
            }),
          })
        ).isRequired,
      }),
    })
  ).isRequired,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  highlightColor: PropTypes.string,
  checkMarkColor: PropTypes.string,
  xMarkColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  headingColor: null,
  textColor: null,
  backgroundColor: null,
  highlightColor: null,
  checkMarkColor: null,
  xMarkColor: null,
  headingFont: null,
  textFont: null,
};

const iconsMap = {
  checkMark: {
    icon: <FaCheckCircle size="28px" />,
    iconStyle: styles.check_mark_color,
  },
  xMark: {
    icon: <FaTimesCircle size="28px" />,
    iconStyle: styles.x_mark_color,
  },
};

function renderCell(rowIndex, { item }, colIndex) {
  const key = `${rowIndex}.${colIndex}`;
  const isHighlighted = colIndex === 1;

  switch (item.type) {
    case 'icon': {
      const { icon, iconStyle } = iconsMap[item.icon];
      const classNames = [styles.icon_wrapper, iconStyle];

      if (isHighlighted) classNames.push(styles.highlight);

      return (
        <div key={key} className={classNames.join(' ')}>
          {icon}
        </div>
      );
    }

    case 'text': {
      const { text, image, imageAlt } = item;
      const isHeading = rowIndex === 0 || colIndex === 0;
      const TagName = isHeading ? 'h4' : 'p';
      const classNames = [styles.row_content];

      if (rowIndex === 0) classNames.push(styles.first_row);
      if (isHighlighted) classNames.push(styles.highlight);

      return (
        <div key={key} className={classNames.join(' ')}>
          {image && (
            <Image alt={imageAlt} src={image} width={130} height={130} placeholder="blur" />
          )}
          <TagName>{text}</TagName>
        </div>
      );
    }
  }
}

export default function Comparison({
  rows,
  headingColor,
  textColor,
  backgroundColor,
  highlightColor,
  checkMarkColor,
  xMarkColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <div className={`root ${styles.comparison_chart_wrapper}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar('--highlight-color', highlightColor, 'var(--secondary-color)')}
          ${buildCssVar('--check-mark-color', checkMarkColor, 'var(--color-positive)')}
          ${buildCssVar('--x-mark-color', xMarkColor, 'var(--color-negative)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.comparison_chart}>
        {rows.map(({ item: { items } }, i) => (
          <div key={i} className={styles.comparison_chart_row}>
            <Fragment>{items.map(renderCell.bind(null, i))}</Fragment>
          </div>
        ))}
      </div>
    </div>
  );
}

Comparison.propTypes = propTypes;
Comparison.defaultProps = defaultProps;

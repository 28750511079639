import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useFunnelData } from './funnel-data-context';
import { usePreviewData } from './preview-data-context';
import { normalizeQueryString } from './query-string';
import { getCartData, parseQueryCart, getMockCartData } from './cart';

/**
 * Cart Context
 * {
 *   cartIsLoaded,
 *   cartIsUpdating,
 *   cartIsReady: cartIsLoaded && !cartIsUpdating,
 *   // From query string, holds contents of cart
 *   cartItems: [
 *     {
 *       offerId,
 *       quantity,
 *     },
 *     ...
 *   ],
 *   // From the Cart API
 *   // https://www.notion.so/matterinc/Cart-API-Spec-0e9653adece2420e9ba81f0a43764984
 *   cartData: {
 *     data: {
 *        currency,
 *        lineItems,
 *        ...
 *     },
 *   },
 *   discountCode,
 *   discountErrorMessage,
 * }
 */
const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const router = useRouter();
  const { funnelId, apiBaseUrl, offerData } = useFunnelData();
  const { preview } = usePreviewData();

  const [cartIsLoaded, setCartIsLoaded] = useState(false);
  const [cartIsUpdating, setCartIsUpdating] = useState(false);
  const [cartItems, setCartItems] = useState();
  const [cartData, setCartData] = useState();
  const [cartDiscountCode, setCartDiscountCode] = useState();
  const [discountErrorMessage, setDiscountErrorMessage] = useState();
  const [cartAddress, setCartAddress] = useState();

  // Build cart from query string
  useEffect(() => {
    if (!router.isReady) return;
    const normalizedQuery = normalizeQueryString(router.query);
    const { oid: offerId, cart: queryCart, code } = normalizedQuery;

    if (code) setCartDiscountCode(code);

    // Maintain backwards compatibility for URLs using old `oid` query param.
    if (offerId) {
      return setCartItems([
        {
          offerId,
          quantity: 1,
        },
      ]);
    }

    if (queryCart) {
      try {
        const items = parseQueryCart(queryCart);
        if (items.length) {
          return setCartItems(items);
        }
      } catch (err) {
        // ignore malformed cart
        console.error(err);
      }
    }
  }, [router.isReady, router.query, preview]);

  // Hit cart API when cart changes
  const lastCartDataTimestamp = useRef(0);
  useEffect(() => {
    if (!cartItems) return;
    const { mock } = cartItems[0];

    const timestamp = Date.now();

    async function updateCartData() {
      setCartIsUpdating(true);
      let result;

      if (mock) {
        result = getMockCartData({
          cartItems,
          offerData,
        });
      } else {
        result = await getCartData({
          apiBaseUrl,
          funnelId,
          cartItems,
          cartDiscountCode,
          cartAddress,
        });
      }

      // Ensure this batch of cart data is the latest we have received.
      if (timestamp > lastCartDataTimestamp.current) {
        lastCartDataTimestamp.current = timestamp;
        setCartData(result);
        setDiscountErrorMessage(result.data?.discountError?.message);
      }

      setCartIsLoaded(true);
      setCartIsUpdating(false);
    }

    updateCartData();
  }, [apiBaseUrl, funnelId, cartItems, cartDiscountCode, setCartData, offerData, cartAddress]);

  // Merges the existing cart item with the updated version.
  const updateCartItem = useCallback(
    updatedItem => {
      setCartItems(
        cartItems.map(item => {
          if (item.offerId === updatedItem.offerId) {
            return { ...item, ...updatedItem };
          }

          return item;
        })
      );
    },
    [cartItems]
  );

  return (
    <CartContext.Provider
      value={{
        cartIsLoaded,
        cartIsUpdating,
        cartIsReady: cartIsLoaded && !cartIsUpdating,
        cartItems,
        setCartItems,
        updateCartItem,
        cartData,
        cartDiscountCode,
        setCartDiscountCode,
        discountErrorMessage,
        setCartAddress,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }

  return context;
};

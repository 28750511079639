import { Controller, useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { CheckoutEmailRegExp } from 'constant';
import { sanitizeData, transformPhone } from 'utils/checkout-form';
import { useCallback, useState } from 'react';
import { identify as klaviyoIdentify, trackSavedProduct } from 'utils/klaviyo';
import { matterIdentify } from 'utils/matter-identify';
import styles from './FunnelCartSaveThis.module.css';
import { useFunnelData } from 'utils/funnel-data-context';
import { FaEnvelope, FaComment, FaChevronLeft, FaCircleNotch } from 'react-icons/fa';

import * as gtm from 'utils/gtm';
import * as rudder from 'utils/rudderstack';

const SaveThisMode = {
  NONE: 'none',
  EMAIL: 'email',
  TEXT: 'text',
};

function FunnelCartSaveThis() {
  const [mode, setMode] = useState(SaveThisMode.NONE);
  const funnelData = useFunnelData();
  const { funnelId, apiBaseUrl, klaviyoPublicKey, offerData, offersConfig } = funnelData;
  const [isProcessing, setIsProcessing] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const {
    handleSubmit: handleSubmitValidation,
    control,
    formState: { errors },
    register,
    reset,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
  });

  const handleSubmit = useCallback(
    values => {
      setIsProcessing(true);
      setShowSuccessMessage(false);

      const { email, phone } = sanitizeData(values);
      const subscribeSms = mode === SaveThisMode.TEXT;
      const data = {};
      if (mode === SaveThisMode.EMAIL) {
        data.email = email;
      } else if (mode === SaveThisMode.TEXT) {
        data.phone = phone;
        data.phoneConsent = true;
      }

      if (window._learnq && window._learnq.isIdentified && window._learnq.isIdentified() !== true) {
        klaviyoIdentify(data);
      }

      matterIdentify({
        customerData: data,
        apiBaseUrl,
        funnelId,
        subscribeSms,
      })
        .then(lead => {
          gtm.onSavePromotion(email, phone, offerData, lead?.id);
          rudder.onSavePromotion(email, phone, offerData, lead?.id);
        })
        .catch(() => {
          gtm.onSavePromotion(email, phone, offerData);
          rudder.onSavePromotion(email, phone, offerData);
        });

      trackSavedProduct(data, offerData, offersConfig, klaviyoPublicKey);

      reset();
      setIsProcessing(false);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        setMode(SaveThisMode.NONE);
      }, 2500);
    },
    [reset, mode, apiBaseUrl, offerData, offersConfig, funnelId, klaviyoPublicKey]
  );

  const [hasFocused, setHasFocusedOn] = useState([]);

  const handleFocus = useCallback(({ target }) => {
    const name = target.name;

    if (!name || hasFocused.includes(name)) return;

    gtm.onFocusPromotion(name);
    rudder.onFocusPromotion(name);
    setHasFocusedOn(hasFocused.concat(name));
  });

  const handleBackButton = useCallback(() => {
    setMode(SaveThisMode.NONE);
    clearErrors(['email', 'phone']);
  }, [clearErrors]);

  return (
    <>
      <h2 className={styles.heading}>Save this promotion link for later!</h2>
      <form
        onSubmit={handleSubmitValidation(handleSubmit)}
        className={styles.form}
        onFocus={handleFocus}
      >
        {!showSuccessMessage && (
          <>
            {mode !== SaveThisMode.NONE && (
              <button className={styles.back_btn} onClick={handleBackButton}>
                <FaChevronLeft />
              </button>
            )}
            {mode !== SaveThisMode.TEXT && (
              <div
                className={`${
                  errors?.email && mode === SaveThisMode.EMAIL ? styles.invalid_field : ''
                } ${styles.input_wrapper}`}
              >
                <FaEnvelope />
                <input
                  id="email"
                  type="text"
                  autoComplete="email"
                  autoCorrect="off"
                  autoCapitalize="none"
                  placeholder="Email me"
                  onFocus={() => setMode(SaveThisMode.EMAIL)}
                  {...register('email', {
                    required: true,
                    pattern: CheckoutEmailRegExp,
                    disabled: mode !== SaveThisMode.EMAIL,
                  })}
                  onKeyDown={({ key }) => {
                    if (key === 'Enter') {
                      handleSubmitValidation(handleSubmit)();
                    }
                  }}
                ></input>
              </div>
            )}
            {mode !== SaveThisMode.EMAIL && (
              <div
                className={`${
                  errors?.phone && mode === SaveThisMode.TEXT ? styles.invalid_field : ''
                } ${styles.input_wrapper}`}
              >
                <FaComment />
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /\+1\d{10}/,
                    disabled: mode !== SaveThisMode.TEXT,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PatternFormat
                      id="phone"
                      name="phone"
                      autoComplete="tel"
                      format="+1 (###) ###-####"
                      mask="_"
                      // Shows expected format instead of placeholder
                      allowEmptyFormatting={mode === SaveThisMode.TEXT}
                      placeholder="Text me"
                      onValueChange={v => {
                        onChange(transformPhone.output(v.value));
                      }}
                      value={transformPhone.input(value)}
                      onFocus={() => setMode(SaveThisMode.TEXT)}
                      onKeyDown={({ key }) => {
                        if (key === 'Enter') {
                          handleSubmitValidation(handleSubmit)();
                        }
                      }}
                    />
                  )}
                ></Controller>
              </div>
            )}
            {mode !== SaveThisMode.NONE && (
              <button className={styles.submit_btn} type="submit" disabled={isProcessing}>
                {!isProcessing && '↵'}
                {isProcessing && <FaCircleNotch spin="true" className={styles.spinner_icon} />}
              </button>
            )}
          </>
        )}
        {showSuccessMessage && (
          <>
            {mode === SaveThisMode.EMAIL && (
              <div className={styles.success_msg}>Sent. Check your email!</div>
            )}
            {mode === SaveThisMode.TEXT && (
              <div className={styles.success_msg}>Sent. Check your messages!</div>
            )}
          </>
        )}
      </form>
    </>
  );
}

export default FunnelCartSaveThis;

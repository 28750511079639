import { usePathname } from 'next/navigation';

const usePageType = () => {
  const path = usePathname();

  const firstPath = path.split('/')?.[1];

  return {
    isUpsellPage: firstPath === 'upsells',
    isSalesPage: firstPath === 'product',
  };
};

export default usePageType;

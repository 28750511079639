import styles from './BrandInformationModal.module.css';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { useHeadingFontOverrides, useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';
import Image from './Image';
import BrandInformationModalItem, {
  queryTemplate as brandInformationModalItemQueryTemplate,
} from './BrandInformationModalItem';
import { FaTimes } from 'react-icons/fa';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on BrandInformationModal': [
    'buttonText',
    { logoImage: ['...FilePartsWithMetadata'] },
    'imageAlt',
    'title',
    'finePrint',
    {
      items: [
        {
          item: [brandInformationModalItemQueryTemplate],
        },
      ],
    },
    'buttonBackgroundColor',
    'buttonHoverColor',
    'buttonTextColor',
    'buttonHoverTextColor',
    'buttonBorderColor',
    'buttonHoverBorderColor',
    'modalBackgroundColor',
    'headingColor',
    'textColor',
    {
      headingFont: ['family'],
    },
    {
      textFont: ['family'],
    },
  ],
};

const propTypes = {
  buttonText: PropTypes.string.isRequired,
  logoImage: PropTypes.object,
  imageAlt: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape(BrandInformationModalItem.propTypes),
    })
  ),
  finePrint: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonHoverTextColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonHoverBorderColor: PropTypes.string,
  modalBackgroundColor: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  logoImage: null,
  imageAlt: null,
  title: null,
  items: null,
  finePrint: null,
  buttonBackgroundColor: null,
  buttonHoverColor: null,
  buttonTextColor: null,
  buttonHoverTextColor: null,
  buttonBorderColor: null,
  buttonHoverBorderColor: null,
  modalBackgroundColor: null,
  headingColor: null,
  textColor: null,
  headingFont: null,
  textFont: null,
};

export default function BrandInformationModal({
  buttonText,
  logoImage,
  imageAlt,
  title,
  items,
  finePrint,
  buttonBackgroundColor,
  buttonHoverColor,
  buttonTextColor,
  buttonHoverTextColor,
  buttonBorderColor,
  buttonHoverBorderColor,
  modalBackgroundColor,
  textColor,
  headingColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const [openModal, setOpenModal] = useState(false);

  const onModalOpen = () => {
    setOpenModal(true);
  };

  const onModalClose = () => {
    setOpenModal(false);
  };

  return (
    <div className={`root ${styles.main}`}>
      <style jsx global>{`
        .root {
          ${buildCssVar('--button-background-color', buttonBackgroundColor, 'var(--primary-color)')}
          ${buildCssVar('--button-hover-color', buttonHoverColor, 'var(--primary-color)')}
          ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-dark)')}
          ${buildCssVar(
            '--button-hover-text-color',
            buttonHoverTextColor,
            'var(--text-color-dark)'
          )}
          ${buildCssVar('--button-border-color', buttonBorderColor, 'var(--primary-color)')}
          ${buildCssVar(
            '--button-hover-border-color',
            buttonHoverBorderColor,
            'var(--primary-color)'
          )}
          ${buildCssVar(
            '--modal-background-color',
            modalBackgroundColor,
            'var(--default-background-color)'
          )}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--heading-color', headingColor, 'var(--text-color-dark)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
        body {
          overflow: ${openModal ? 'hidden' : 'scroll'};
        }
      `}</style>

      {buttonText && (
        <button className={styles.modal_btn} onClick={onModalOpen}>
          {buttonText}
        </button>
      )}

      <div
        className={`${styles.modal_wrapper} ${openModal ? styles.show_modal : styles.hide_modal}`}
      >
        <div className={styles.modal}>
          <button onClick={onModalClose} className={styles.modal_close_btn}>
            <FaTimes />
          </button>
          {logoImage && <Image className={styles.logo_image} src={logoImage} alt={imageAlt} />}
          {title && <h1 className={styles.heading}>{title}</h1>}
          {!!items.length &&
            items.map(({ item }, i) => {
              return <BrandInformationModalItem key={i} {...item} />;
            })}
          {finePrint && (
            <div className={styles.text}>
              <MarkdownText text={finePrint} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

BrandInformationModal.propTypes = propTypes;
BrandInformationModal.defaultProps = defaultProps;

import dynamic from 'next/dynamic';
import { useTheme } from 'utils/theme-context';
import { Themes } from 'constant';

const SectionsTheme1 = dynamic(() => import('./Sections.theme1'));
const SectionsTheme2 = dynamic(() => import('./Sections.theme2'));

export default function Sections(props) {
  const { theme } = useTheme();
  return theme === Themes.THEME_1 ? <SectionsTheme1 {...props} /> : <SectionsTheme2 {...props} />;
}

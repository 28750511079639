import { asyncDebounce } from './async';
import fetcher from './fetcher';
import { parsePrice } from './price';

/**
 * Hits the CartAPI to generate cartData from cartItems
 * @param {Array[Object]} cartItems - see parseQueryCart below
 */
export const getCartData = asyncDebounce(
  async ({ apiBaseUrl, funnelId, cartItems, cartDiscountCode, cartAddress }) => {
    const result = await fetcher(`${apiBaseUrl}/cart`, {
      // Needed for CORS request to send cookies
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        funnelId,
        cartItems: getValidCartItems(cartItems),
        discountCode: cartDiscountCode,
        address: cartAddress,
      }),
    });
    return result;
  },
  200
);

// Whitelist fields for url, and filter out quantity zero items.
export function getValidCartItems(cartItems) {
  return (
    cartItems
      // Whitelist fields to avoid putting unexpected fields in the query string
      .map(({ offerId, quantity }) => ({ offerId, quantity }))
      .filter(({ quantity }) => quantity > 0)
  );
}

/**
 * const cartItems = [
 *   { offerId, quantity },
 *   ...
 * ]
 */
export function buildCartQuery(cartItems) {
  return encodeURIComponent(JSON.stringify(getValidCartItems(cartItems)));
}

/**
 * Parses cart query param into cartItems structure.
 * @param {String} queryCart - query string `cart` parameter built in utils/checkout-link
 * @returns Array of cartItem Objects
 */
export function parseQueryCart(queryCart) {
  return JSON.parse(queryCart);
}

/**
 * Returns a mock cart data response. Currently only support cart items and their quantity.
 * This is good enough for now as the mock data is only needed on the sales page which does not
 * consider discount code in most cases. If discount code comes in on the query string it will be ignored.
 *
 * In order to support discount code, we may want to move this logic to the admin server. Or
 * possibly choose to always show no discount.
 */
export function getMockCartData({ cartItems, offerData }) {
  let subtotal = 0;

  const lineItems = cartItems.map(({ offerId, quantity }) => {
    const { name, compareAtPrice, price, store } = offerData.data.find(el => el.id === offerId);

    const totalPrice = parsePrice(price) * quantity;
    subtotal += totalPrice;

    return {
      mock: true,
      offerId,
      name,
      brandName: store.brand?.name,
      compareAtPrice,
      price,
      originalPrice: price,
      quantity,
      totalOriginalPrice: totalPrice.toFixed(2),
      totalPrice: totalPrice.toFixed(2),
      metric1: '0.00',
    };
  });

  return {
    data: {
      mock: true,
      lineItems,
      subtotal: subtotal.toFixed(2),
      total: subtotal.toFixed(2),
      currency: offerData.data[0].store.currency,
      paypalMerchantId: offerData.data[0].store.paypalMerchantId,
    },
  };
}

import PropTypes from 'prop-types';
import { buildCssVar } from 'utils/style-override';
import Sections from './Sections';
import styles from './TwoColumn.module.css';
import { queryTemplate as imageCarouselQueryTemplate } from './ImageCarousel';
import { queryTemplate as columnImageQueryTemplate } from './ColumnImage';
import { queryTemplate as multiBundleQueryTemplate } from './MultiBundle';
import { queryTemplate as peopleLookingQueryTemplate } from './PeopleLooking';
import { queryTemplate as reviewStarsQueryTemplate } from './ReviewStars';
import { queryTemplate as headingTextQueryTemplate } from './HeadingText';
import { queryTemplate as calloutQueryTemplate } from './Callout';
import { queryTemplate as bulletListQueryTemplate } from './BulletList';
import { queryTemplate as accordionQueryTemplate } from './Accordion';
import { queryTemplate as quantitySelectorQueryTemplate } from './QuantitySelector';
import { queryTemplate as funnelCartQueryTemplate } from './FunnelCart';

export const queryTemplate = {
  '... on TwoColumn': [
    'sectionId',
    'stickyLeftColumn',
    'columnsVerticallyCentered',
    {
      leftColumn: [
        'id',
        'collection',
        {
          item: [imageCarouselQueryTemplate, columnImageQueryTemplate],
        },
      ],
    },
    {
      rightColumn: [
        'id',
        'collection',
        {
          item: [
            multiBundleQueryTemplate,
            peopleLookingQueryTemplate,
            reviewStarsQueryTemplate,
            headingTextQueryTemplate,
            calloutQueryTemplate,
            bulletListQueryTemplate,
            accordionQueryTemplate,
            quantitySelectorQueryTemplate,
            funnelCartQueryTemplate,
          ],
        },
      ],
    },
    'backgroundColor',
  ],
};

const propTypes = {
  sectionId: PropTypes.string,
  leftColumn: PropTypes.arrayOf(PropTypes.object).isRequired,
  rightColumn: PropTypes.arrayOf(PropTypes.object).isRequired,
  stickyLeftColumn: PropTypes.bool,
  columnsVerticallyCentered: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

const defaultProps = {
  sectionId: null,
  stickyLeftColumn: false,
  columnsVerticallyCentered: false,
  backgroundColor: null,
};

export default function TwoColumn({
  sectionId,
  leftColumn,
  rightColumn,
  stickyLeftColumn,
  columnsVerticallyCentered,
  backgroundColor,
}) {
  const rootClassNames = ['root', styles.container];
  if (backgroundColor) rootClassNames.push(styles.top_bottom_padding);

  return (
    <div id={sectionId} className={rootClassNames.join(' ')}>
      <style jsx>{`
        .root {
          ${buildCssVar(
            '--background-color',
            backgroundColor,

            'var(--default-background-color)'
          )}
        }
      `}</style>

      <div
        className={`${styles.sec_wrapper} ${
          columnsVerticallyCentered ? styles.vertically_centered : ''
        }`}
      >
        <div>
          <div className={stickyLeftColumn ? styles.sticky_left_column : ''}>
            <Sections sections={leftColumn} />
          </div>
        </div>
        <div className={styles.right_column_container}>
          <Sections sections={rightColumn} />
        </div>
      </div>
    </div>
  );
}

TwoColumn.propTypes = propTypes;
TwoColumn.defaultProps = defaultProps;

import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { videoPropTypes } from 'utils/prop-types';
import { getDirectusFileUrl } from 'utils/directus';
import styles from './VideoItem.module.css';
import { usePreviewData } from 'utils/preview-data-context';
import { missingComponentField } from 'utils/error';

export const queryTemplate = {
  '... on VideoItem': [
    { placeholderImage: ['...FileParts'] },
    { video: ['...FileParts'] },
    'videoAlt',
  ],
};

export const propTypes = {
  placeholderImage: PropTypes.object.isRequired,
  video: videoPropTypes.isRequired,
  videoAlt: PropTypes.string.isRequired,
};

const defaultProps = {};

export default function VideoItem({ placeholderImage, video, videoAlt }) {
  const { preview } = usePreviewData();

  useEffect(() => {
    if (!video) missingComponentField('video', 'video file', preview);

    if (!placeholderImage) missingComponentField('video', 'placeholder image', preview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { videoSrc, placeholderSrc, type } = useMemo(
    () => ({
      videoSrc: getDirectusFileUrl(video),
      placeholderSrc: getDirectusFileUrl(placeholderImage),
      type: video?.type,
    }),
    [video, placeholderImage]
  );

  return (
    <video className={styles.video} alt={videoAlt} controls poster={placeholderSrc}>
      <source src={videoSrc} type={type} />
    </video>
  );
}

VideoItem.propTypes = propTypes;
VideoItem.defaultProps = defaultProps;

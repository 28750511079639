import styles from './MultiProduct.module.css';
import PropTypes from 'prop-types';
import { useFunnelData } from 'utils/funnel-data-context';
import { usePreviewData } from 'utils/preview-data-context';
import { useOnCheckoutClick } from 'utils/checkout-link';
import { useEffect, useMemo, useState } from 'react';
import MultiProductButton, {
  productPropTypes,
  queryTemplate as multiProductButtonQueryTemplate,
} from './MultiProductButton';
import { trackViewedProduct } from 'utils/klaviyo';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { missingPageOffer } from 'utils/error';
import { buildCssVar } from 'utils/style-override';
import Spinner from './Spinner';

export const queryTemplate = {
  '... on MultiProduct': [
    {
      products: [
        {
          item: [multiProductButtonQueryTemplate],
        },
      ],
    },
    'shippingText',
    'ctaText',
    'ctaSubtext',
    'productInfoTextColor',
    'productInfoBackgroundColor',
    'buttonTextColor',
    'buttonHoverTextColor',
    'buttonBackgroundColor',
    'buttonHoverBackgroundColor',
    'buttonBorderColor',
    'buttonHoverBorderColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape(productPropTypes).isRequired,
    })
  ).isRequired,
  shippingText: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaSubtext: PropTypes.string,
  productInfoTextColor: PropTypes.string,
  productInfoBackgroundColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonHoverTextColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonHoverBackgroundColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonHoverBorderColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  shippingText: null,
  ctaSubtext: null,
  productInfoTextColor: null,
  productInfoBackgroundColor: null,
  buttonTextColor: null,
  buttonHoverTextColor: null,
  buttonBackgroundColor: null,
  buttonHoverBackgroundColor: null,
  buttonBorderColor: null,
  buttonHoverBorderColor: null,
  headingFont: null,
  textFont: null,
};

export default function MultiProduct({
  products,
  shippingText,
  ctaText,
  ctaSubtext,
  productInfoTextColor,
  productInfoBackgroundColor,
  buttonTextColor,
  buttonHoverTextColor,
  buttonBackgroundColor,
  buttonHoverBackgroundColor,
  buttonBorderColor,
  buttonHoverBorderColor,
  headingFont,
  textFont,
}) {
  const { preview } = usePreviewData();
  const { offerData, offersConfig } = useFunnelData();
  const [shouldShowActivityIndicator, setShouldShowActivityIndicator] = useState(false);

  const getCheckoutClickHandler = useOnCheckoutClick({ setShouldShowActivityIndicator });

  const productsWithOffer = useMemo(() => {
    if (offerData.mock) console.warn('Using mock offer data');
    return products.map(({ item }, i) => {
      const config = offersConfig?.find(el => el.directusProductId === item.id);

      let offer;
      if (offerData.mock) {
        offer = offerData.data[i];
      } else {
        offer = offerData.data.find(el => el.id === config?.id);
      }

      return { ...item, offer, config };
    });
  }, [products, offerData, offersConfig]);

  // Offer/Directus Product mapping validation
  useEffect(() => {
    productsWithOffer.forEach(product => {
      if (!product.offer) missingPageOffer(product.id, preview);
    });
  }, [productsWithOffer, preview]);

  const [selectedProduct, setSelectedProduct] = useState(productsWithOffer[0]);

  useEffect(() => {
    productsWithOffer.forEach(({ offer, config }) => trackViewedProduct(offer, config));
  }, [productsWithOffer]);

  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar(
            '--product-info-text-color',
            productInfoTextColor,
            'var(--text-color-dark)'
          )}
          ${buildCssVar(
            '--product-info-background-color',
            productInfoBackgroundColor,
            'var(--secondary-color)'
          )}
          ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-light)')}
          ${buildCssVar(
            '--button-hover-text-color',
            buttonHoverTextColor,
            'var(--text-color-light)'
          )}
          ${buildCssVar('--button-background-color', buttonBackgroundColor, 'var(--primary-color)')}
          ${buildCssVar(
            '--button-hover-background-color',
            buttonHoverBackgroundColor,
            'var(--primary-color)'
          )}
          ${buildCssVar(
            '--button-border-color',
            buttonBorderColor,
            buttonBackgroundColor,
            'var(--primary-color)'
          )}
          ${buildCssVar(
            '--button-hover-border-color',
            buttonHoverBorderColor,
            buttonHoverBackgroundColor,
            'var(--primary-color)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {shouldShowActivityIndicator && <Spinner />}
      <div className={styles.products_wrapper}>
        {productsWithOffer.map(product => {
          const isSelected = product.id === selectedProduct.id;

          return (
            <div key={product.id} className={styles.product_wrapper}>
              <MultiProductButton
                onClick={setSelectedProduct}
                isSelected={isSelected}
                product={product}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.product_info_wrapper}>
        <div className={styles.product_info_container}>
          <div className={styles.product_info}>
            <div className={styles.mini_cart}>
              <h2 className={styles.product_name}>
                {selectedProduct.name || selectedProduct.offer?.name}
              </h2>
              <div className={styles.price_wrapper}>
                <h2 className={styles.price}>${selectedProduct.offer?.price}</h2>
                <h4>{shippingText}</h4>
              </div>
            </div>
          </div>

          <button
            className={styles.cta_button}
            onClick={getCheckoutClickHandler([{ offerId: selectedProduct.offer?.id, quantity: 1 }])}
          >
            <h2>{ctaText}</h2>
            {ctaSubtext && <p className={styles.cta_subtext}>{ctaSubtext}</p>}
          </button>
        </div>
      </div>
    </div>
  );
}

MultiProduct.propTypes = propTypes;
MultiProduct.defaultProps = defaultProps;

import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styles from './Comparison.module.css';
import ComparisonTable, { queryTemplate as comparisonTableQueryTemplate } from './ComparisonTable';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on Comparison': [
    'title',
    'text',
    {
      table: [
        {
          rows: [
            {
              item: [comparisonTableQueryTemplate],
            },
          ],
        },
        'headingColor',
        'textColor',
        'backgroundColor',
        'highlightColor',
        'checkMarkColor',
        'xMarkColor',
        { headingFont: ['family'] },
        { textFont: ['family'] },
      ],
    },
    'headingColor',
    'textColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  table: PropTypes.shape(ComparisonTable.propTypes).isRequired,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  title: null,
  text: undefined,
  headingColor: null,
  textColor: null,
  headingFont: null,
  textFont: null,
};

export default function Comparison({
  title,
  text,
  table,
  headingColor,
  textColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={`${styles.comparison_wrapper} ${styles.sec_wrapper}`}>
        {title && <h1 className={styles.heading}>{title}</h1>}
        {text && <MarkdownText text={text} />}
        <ComparisonTable {...table} />
      </div>
    </div>
  );
}

Comparison.propTypes = propTypes;
Comparison.defaultProps = defaultProps;

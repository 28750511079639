import { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import { useFunnelData } from 'utils/funnel-data-context';
import { usePreviewData } from 'utils/preview-data-context';
import { trackViewedProduct } from 'utils/klaviyo';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { missingPageOffer } from 'utils/error';
import { buildCssVar } from 'utils/style-override';

import EnhancedBundleItemDesktop from './Desktop';
import EnhancedBundleItemMobile from './Mobile';

const MediaQuery = dynamic(() => import('react-responsive'), {
  ssr: false,
});

export const queryTemplate = {
  '... on MultiVariantBundleItem': [
    'heading',
    {
      previewImages: [
        {
          '... on MultiVariantBundleItem_previewImages': [
            {
              item: [
                {
                  '... on MultiVariantBundleItem_Images': [
                    { preview: ['...FilePartsWithMetadata'] },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      variants: [
        {
          item: [
            {
              '... on Product': [
                'id',
                'type',
                'name',
                'variantName',
                { image: ['...FilePartsWithMetadata'] },
                'text',
                'rating',
                'backgroundColor',
                'borderColor',
                'textColor',
                'compareAtColor',
                'starColor',
                'starEmptyColor',
              ],
            },
          ],
        },
      ],
    },
    'ctaText',
    'headingColor',
    'headingBackgroundColor',
    'buttonTextColor',
    'buttonBackgroundColor',
    'buttonBorderColor',
    'buttonHoverBorderColor',
    'buttonHoverColor',
    'buttonHoverTextColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

export const itemPropTypes = {
  heading: PropTypes.string,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string,
        variantName: PropTypes.string.isRequired,
        image: PropTypes.object.isRequired,
        text: PropTypes.string,
        rating: PropTypes.number,
        backgroundColor: PropTypes.string,
        borderColor: PropTypes.string,
        compareAtColor: PropTypes.string,
        textColor: PropTypes.string,
        starColor: PropTypes.string,
        starEmptyColor: PropTypes.string,
      }),
    })
  ).isRequired,
  ctaText: PropTypes.string.isRequired,
  headingColor: PropTypes.string,
  headingBackgroundColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonHoverBorderColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  buttonHoverTextColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const propTypes = {
  handleVariantNameChange: PropTypes.func.isRequired,
  selectedVariantName: PropTypes.string.isRequired,
  ...itemPropTypes,
};

const defaultProps = {
  heading: null,
  headingColor: null,
  headingBackgroundColor: null,
  buttonTextColor: null,
  buttonBackgroundColor: null,
  buttonHoverColor: null,
  buttonHoverTextColor: null,
  buttonBorderColor: null,
  buttonHoverBorderColor: null,
  headingFont: null,
  textFont: null,
};

function EnhancedBundleItem({
  heading,
  ctaText,
  variants,
  headingColor,
  headingBackgroundColor,
  buttonTextColor,
  buttonBackgroundColor,
  buttonHoverColor,
  buttonHoverTextColor,
  buttonBorderColor,
  buttonHoverBorderColor,
  headingFont,
  previewImages,
  textFont,
}) {
  const [selectedVariantName, setSelectedVariantName] = useState(variants?.[0]?.item?.variantName);

  const handleVariantNameChange = useCallback(variantName => {
    setSelectedVariantName(variantName);
  }, []);

  const { preview } = usePreviewData();
  const { offersConfig, offerData } = useFunnelData();

  const handleVariantChange = useCallback(
    e => {
      // Making the parent aware that the variant type is changed so it can update all the children
      handleVariantNameChange(e.target.value);
    },
    [handleVariantNameChange]
  );

  const variantsWithOffer = useMemo(() => {
    if (offerData.mock) console.warn('Using mock offer data');
    return variants.map(({ item }, i) => {
      if (item.type !== 'multiVariantBundle') {
        console.error(
          `Incorrect Product type for Multi Variant Bundle: Products must be of type "Multi Variant Bundle"`,
          { id: item.id, type: item.type }
        );
      }

      const config = offersConfig?.find(el => el.directusProductId === item.id);

      let offer;
      if (offerData.mock) {
        offer = offerData.data[i];
      } else {
        offer = offerData.data.find(el => el.id === config?.id);
      }

      return { ...item, offer, config };
    });
  }, [variants, offerData, offersConfig]);

  // Offer/Directus Product mapping validation
  useEffect(() => {
    variantsWithOffer.forEach(variant => {
      if (!variant.offer) missingPageOffer(variant.id, preview);
    });
  }, [variantsWithOffer, preview]);

  const [selectedVariant, setSelectedVariant] = useState(variantsWithOffer[0]);

  useEffect(() => {
    variantsWithOffer.forEach(({ offer, config }) => trackViewedProduct(offer, config));
  }, [variantsWithOffer]);

  useEffect(() => {
    // This updates the selected variant based on the new variant name coming from the parent
    setSelectedVariant(variantsWithOffer.find(el => el.variantName === selectedVariantName));
  }, [selectedVariantName, variants, variantsWithOffer]);

  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const buttonBorderColorOverride = buttonBorderColor || buttonBackgroundColor;
  const buttonHoverBorderColorOverride = buttonHoverBorderColor || buttonHoverColor;
  const cssVarsText = `
  .root {
    ${buildCssVar('--heading-color', headingColor, 'var(--primary-color)')}
    ${buildCssVar('--heading-background-color', headingBackgroundColor, 'var(--secondary-color)')}
    ${buildCssVar(
      '--background-color',
      selectedVariant?.backgroundColor,
      'var(--default-background-color)'
    )}
    ${buildCssVar('--border-color', selectedVariant?.borderColor, 'var(--primary-color)')}
    ${buildCssVar('--text-color', selectedVariant?.textColor, 'var(--text-color)')}
    ${buildCssVar('--compare-at-color', selectedVariant?.compareAtColor, 'var(--color-negative)')}
    ${buildCssVar('--saving-at-color', selectedVariant?.savingAtColor, 'var(--color-positive)')}
    ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-light)')}
    ${buildCssVar('--button-background-color', buttonBackgroundColor, 'var(--primary-color)')}
    ${buildCssVar('--button-hover-color', buttonHoverColor, 'var(--primary-color)')}
    ${buildCssVar('--button-hover-text-color', buttonHoverTextColor, 'var(--text-color-light)')}
    ${buildCssVar('--button-border-color', buttonBorderColorOverride, 'var(--primary-color)')}
    ${buildCssVar(
      '--button-hover-border-color',
      buttonHoverBorderColorOverride,
      'var(--primary-color)'
    )}
    ${headingFontOverrides ?? ''}
    ${textFontOverrides ?? ''}
  }
`;
  return (
    <>
      <MediaQuery maxWidth={980}>
        <EnhancedBundleItemMobile
          ctaText={ctaText}
          selectedVariantName={selectedVariantName}
          variantsWithOffer={variantsWithOffer}
          image={selectedVariant?.image}
          cssVarsText={cssVarsText}
          compareAtPrice={selectedVariant?.offer?.compareAtPrice}
          price={selectedVariant?.offer?.price}
          configName={selectedVariant?.config?.name}
          description={selectedVariant?.text}
          heading={heading}
          previewImages={previewImages}
          offerName={selectedVariant?.offer?.name}
          onVariantChange={handleVariantChange}
          variantName={selectedVariant?.name}
          rating={selectedVariant?.rating}
          starColor={selectedVariant?.starColor}
          starEmptyColor={selectedVariant?.starEmptyColor}
        />
      </MediaQuery>
      <MediaQuery minWidth={981}>
        <EnhancedBundleItemDesktop
          ctaText={ctaText}
          selectedVariantName={selectedVariantName}
          variantsWithOffer={variantsWithOffer}
          image={selectedVariant?.image}
          cssVarsText={cssVarsText}
          compareAtPrice={selectedVariant?.offer?.compareAtPrice}
          price={selectedVariant?.offer?.price}
          configName={selectedVariant?.config?.name}
          description={selectedVariant?.text}
          heading={heading}
          previewImages={previewImages}
          offerName={selectedVariant?.offer?.name}
          onVariantChange={handleVariantChange}
          variantName={selectedVariant?.name}
        />
      </MediaQuery>
    </>
  );
}

EnhancedBundleItem.propTypes = propTypes;
EnhancedBundleItem.defaultProps = defaultProps;

export default EnhancedBundleItem;

import PropTypes from 'prop-types';
import { queryTemplate as multiBundleItemQueryTemplate } from './MultiBundleItem.theme1';

export const queryTemplate = {
  '... on MultiBundle': [
    {
      bundles: [
        {
          item: [multiBundleItemQueryTemplate],
        },
      ],
    },
    'showProductUnitPrice',
  ],
};

const propTypes = {
  bundles: PropTypes.arrayOf(PropTypes.object).isRequired,
  showProductUnitPrice: PropTypes.bool,
  MultiBundleItem: PropTypes.elementType.isRequired,
  styles: PropTypes.object.isRequired,
};

const defaultProps = {
  showProductUnitPrice: false,
};

export default function MultiBundle({ bundles, showProductUnitPrice, MultiBundleItem, styles }) {
  return (
    <div className={styles.container}>
      {bundles.map(({ item }, i) => (
        <MultiBundleItem key={i} {...item} showProductUnitPrice={showProductUnitPrice} />
      ))}
    </div>
  );
}

MultiBundle.propTypes = propTypes;
MultiBundle.defaultProps = defaultProps;

import { useState, useEffect } from 'react';

import { useShippingCountryStore } from 'hooks/useShippingCountryStore';

import { useFunnelData } from 'utils/funnel-data-context';

export default function useLocalCurrency(selectedCountry?: string) {
  // @ts-ignore
  const { shippingCountry, setShippingCountry } = useShippingCountryStore();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const res = await fetch('/api/get-country');
        const data = await res.json();

        if (!selectedCountry) {
          setShippingCountry(data.country || 'US');
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCountry();
  }, [selectedCountry, setShippingCountry]);

  const { currency: storeCurrency, fxRates } = useFunnelData();

  if (!fxRates) {
    return {
      country: shippingCountry,
      currency: storeCurrency,
      symbol: '$',
      rate: 1,
      loading,
      error,
    };
  }

  const { currency, symbol, rate } = fxRates[selectedCountry || shippingCountry] || {};

  return { country: shippingCountry, currency, symbol, rate, loading, error };
}

import styles from './AfterpayWidget.module.css';
import PropTypes from 'prop-types';
import { useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

export const queryTemplate = ['badgeTheme', 'modalTheme', 'textColor', { textFont: ['family'] }];

export const itemPropTypes = {
  badgeTheme: PropTypes.string,
  modalTheme: PropTypes.string,
  textColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const propTypes = {
  ...itemPropTypes,
  price: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

const defaultProps = {
  badgeTheme: 'black-on-mint',
  modalTheme: 'mint',
  textColor: null,
  textFont: null,
};

function AfterpayWidget({ price, currency, badgeTheme, modalTheme, textColor, textFont }) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>
        {`
          .root {
            ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
            ${textFontOverrides ?? ''}
          }
        `}
      </style>
      <afterpay-placement
        data-locale="en_US"
        data-currency={currency}
        data-amount={price}
        data-badge-theme={badgeTheme}
        data-modal-theme={modalTheme}
      ></afterpay-placement>
    </div>
  );
}

AfterpayWidget.propTypes = propTypes;
AfterpayWidget.defaultProps = defaultProps;

export default AfterpayWidget;

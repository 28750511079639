import PropTypes from 'prop-types';

export const offerDataPropType = PropTypes.shape({
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.string,
      directusProductId: PropTypes.string,
      compareAtPrice: PropTypes.string,
      image: PropTypes.string,
      imageBlurDataUrl: PropTypes.string,
      imageWidth: PropTypes.number,
      imageHeight: PropTypes.number,
      amazonPrice: PropTypes.string,
      amazonLink: PropTypes.string,
      maxQuantity: PropTypes.number,
      updatedAt: PropTypes.string,
      storeProductQuantity: PropTypes.number,
      itemListIndex: PropTypes.number,
      itemListId: PropTypes.number,
      store: PropTypes.shape({
        currency: PropTypes.string,
        paypalMerchantId: PropTypes.string,
        paypalOnboardingStatus: PropTypes.string,
        afterpaySupported: PropTypes.bool,
        brand: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    })
  ),
});

const displayPropTypes = {
  ogTitle: PropTypes.string,
};

export const funnelDataPropType = PropTypes.shape({
  ...displayPropTypes,
  offerData: offerDataPropType,
  funnelId: PropTypes.string,
  funnelName: PropTypes.string,
  upsellUrl: PropTypes.string,
  apiBaseUrl: PropTypes.string,
  canonicalUrl: PropTypes.string,
});

export const upsellFunnelDataPropType = PropTypes.shape({
  ...displayPropTypes,
  cartData: PropTypes.shape({
    data: PropTypes.object,
  }),
  cartItems: PropTypes.arrayOf(PropTypes.object),
  funnelId: PropTypes.string,
  funnelName: PropTypes.string,
  defaultPath: PropTypes.string,
  upsellUrl: PropTypes.string,
  downsellUrl: PropTypes.string,
  apiBaseUrl: PropTypes.string,
});

export const videoPropTypes = PropTypes.shape({
  type: PropTypes.string.isRequired,
  filename_disk: PropTypes.string.isRequired,
  filesize: PropTypes.string.isRequired,
});

export const reviewPropTypes = {
  userName: PropTypes.string,
  userImage: PropTypes.object,
  title: PropTypes.string,
  text: PropTypes.string,
  rating: PropTypes.number.isRequired,
  userNameColor: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  userInfoBackgroundColor: PropTypes.string,
  verifiedColor: PropTypes.string,
  starColor: PropTypes.string,
  starEmptyColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styles from './Testimonial.theme1.module.css';
import TestimonialRating, {
  queryTemplate as testimonialRatingQueryTemplate,
} from './TestimonialRating.theme1';
import SocialReview, { queryTemplate as socialReviewQueryTemplate } from './SocialReview.theme1';
import AmazonReview, { queryTemplate as amazonReviewQueryTemplate } from './AmazonReview';
import AutoplayCarouselTheme1 from './AutoplayCarousel.theme1';
import { reviewPropTypes } from 'utils/prop-types';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on Testimonial': [
    'sectionId',
    'tagline',
    'title',
    'text',
    {
      ratings: [
        {
          item: [testimonialRatingQueryTemplate],
        },
      ],
    },
    {
      socialReviews: [
        {
          item: [socialReviewQueryTemplate],
        },
      ],
    },
    {
      amazonReviews: [
        {
          item: [amazonReviewQueryTemplate],
        },
      ],
    },
    {
      moreReviews: [
        'heading',
        'rating',
        'subheading',
        'reviewsAlwaysVisible',
        {
          items: [
            {
              item: [amazonReviewQueryTemplate],
            },
          ],
        },
        'buttonText',
        'backgroundColor',
        'headingColor',
        'ratingTextColor',
        'subheadingColor',
        'buttonColor',
        'buttonHoverColor',
        'buttonBorderColor',
        'buttonHoverBorderColor',
        'buttonTextColor',
        'buttonHoverTextColor',
        'starColor',
        'starEmptyColor',
        { headingFont: ['family'] },
        { textFont: ['family'] },
        { itemHeadingFont: ['family'] },
        { itemTextFont: ['family'] },
      ],
    },
    'taglineColor',
    'headingColor',
    'textColor',
    'backgroundColor',
    'ratingsBackgroundColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
    { itemHeadingFont: ['family'] },
    { itemTextFont: ['family'] },
  ],
};

const propTypes = {
  sectionId: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ratings: PropTypes.arrayOf(
    PropTypes.shape({ item: PropTypes.shape(TestimonialRating.propTypes) })
  ),
  socialReviews: PropTypes.arrayOf(PropTypes.shape({ item: PropTypes.shape(reviewPropTypes) })),
  amazonReviews: PropTypes.arrayOf(PropTypes.shape({ item: PropTypes.shape(reviewPropTypes) })),
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  ratingsBackgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
  itemHeadingFont: PropTypes.shape({ family: PropTypes.string }),
  itemTextFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  sectionId: null,
  title: null,
  text: undefined,
  ratings: [],
  socialReviews: [],
  amazonReviews: [],
  headingColor: null,
  textColor: null,
  backgroundColor: null,
  ratingsBackgroundColor: null,
  headingFont: null,
  textFont: null,
  itemHeadingFont: null,
  itemTextFont: null,
};

export default function Testimonial({
  sectionId,
  title,
  ratings,
  text,
  socialReviews,
  amazonReviews,
  headingColor,
  textColor,
  backgroundColor,
  ratingsBackgroundColor,
  headingFont,
  textFont,
  itemHeadingFont,
  itemTextFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <div id={sectionId} className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar(
            '--ratings-background-color',
            ratingsBackgroundColor,
            'var(--default-background-color)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.testimonial_section}>
        <div className={styles.sec_wrapper}>
          {title && <h1 className={styles.testimonial_heading}>{title}</h1>}
          {text && (
            <div className={styles.testimonial_text}>
              <MarkdownText text={text} />
            </div>
          )}

          {!!ratings.length && (
            <div className={styles.ratings_wrapper}>
              {ratings.map(({ item }, i) => (
                <TestimonialRating key={i} headingFont={itemHeadingFont} {...item} />
              ))}
            </div>
          )}

          <div className={`${styles.testimonial_social_wrapper} ${styles.desktop}`}>
            {socialReviews.map((review, i) => (
              <SocialReview
                key={i}
                showHeader={true}
                review={{
                  ...review,
                  item: { headingFont: itemHeadingFont, textFont: itemTextFont, ...review.item },
                }}
              />
            ))}
          </div>
          <div className={` ${styles.testimonial_social_wrapper} ${styles.mobile}`}>
            <AutoplayCarouselTheme1
              shouldAutoplay={false}
              showButtons
              items={socialReviews.map((review, i) => (
                <div key={i} className={styles.carousel_item_wrapper}>
                  <SocialReview
                    review={{
                      ...review,
                      item: {
                        headingFont: itemHeadingFont,
                        textFont: itemTextFont,
                        ...review.item,
                      },
                    }}
                    showHeader={true}
                    containerStyle={styles.carousel_item}
                    headerStyle={styles.social_reviews_header}
                  />
                </div>
              ))}
            />
          </div>

          <div className={styles.testimonial_amazon}>
            {amazonReviews.map((review, i) => {
              return (
                <AmazonReview
                  key={i}
                  review={{
                    ...review,
                    item: { headingFont: itemHeadingFont, textFont: itemTextFont, ...review.item },
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

import { OrderPaymentMethod, StripeWalletName } from 'constant';

const sanitizeDatum = (key, value) => {
  let sanitized = value;

  // General sanitize
  if (sanitized && typeof sanitized === 'string') {
    sanitized = sanitized.trim();
  }

  // Field-specific sanitize
  switch (key) {
    case 'email': {
      if (sanitized) return sanitized.toLowerCase();
      return sanitized;
    }

    default:
      return sanitized;
  }
};

export const sanitizeData = data => {
  if (!data) return;
  // Filters out empty values
  return Object.keys(data).reduce((acc, key) => {
    const value = data[key];
    if (value || value === false) return { ...acc, [key]: sanitizeDatum(key, value) };
    return acc;
  }, {});
};

export const transformPhone = {
  output: value => (!value ? '' : `+1${value}`),
  input: value => (!value ? '' : value.slice(2)),
};

export const getDigitalWalletPaymentMethod = walletName => {
  switch (walletName) {
    case StripeWalletName.APPLE_PAY:
      return OrderPaymentMethod.APPLE_PAY;
    case StripeWalletName.GOOGLE_PAY:
      return OrderPaymentMethod.GOOGLE_PAY;
    case StripeWalletName.BROWSER_CARD:
      return OrderPaymentMethod.BROWSER_CARD;
    default:
      return walletName;
  }
};

export const getDigitalWalletExpressPaymentMethod = walletName => {
  switch (walletName) {
    case StripeWalletName.APPLE_PAY:
      return OrderPaymentMethod.APPLE_PAY_EXPRESS;
    case StripeWalletName.GOOGLE_PAY:
      return OrderPaymentMethod.GOOGLE_PAY_EXPRESS;
    case StripeWalletName.BROWSER_CARD:
      return OrderPaymentMethod.CREDIT_CARD_EXPRESS;
    default:
      return walletName;
  }
};

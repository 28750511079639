import styles from './LabelText.module.css';
import PropTypes from 'prop-types';
import { useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

export const queryTemplate = {
  '... on LabelText': ['label', 'text', 'labelColor', 'textColor', { headingFont: ['family'] }],
};

const propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  textColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  labelColor: null,
  textColor: null,
  headingFont: null,
};

export default function LabelText({ label, text, labelColor, textColor, headingFont }) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--label-color', labelColor, 'var(--text-color-light)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-light)')}
          ${headingFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.label_text_wrapper}>
        <h4 className={styles.label}>{label}</h4>
        <h4 className={styles.text}>{text}</h4>
      </div>
    </div>
  );
}

LabelText.propTypes = propTypes;
LabelText.defaultProps = defaultProps;

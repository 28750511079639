import PropTypes from 'prop-types';
import Image from './Image';

export const queryTemplate = {
  '... on ColumnImage': [{ image: ['...FilePartsWithMetadata'] }, 'imageAlt'],
};

const propTypes = {
  image: PropTypes.object.isRequired,
  imageAlt: PropTypes.string.isRequired,
};

const defaultProps = {};

export default function ColumnImage({ image, imageAlt }) {
  return (
    <div>
      <Image src={image} alt={imageAlt} placeholder="blur" />
    </div>
  );
}

ColumnImage.propTypes = propTypes;
ColumnImage.defaultProps = defaultProps;

import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import styles from './Video.module.css';
import Item, {
  propTypes as itemPropTypes,
  queryTemplate as videoItemQueryTemplate,
} from './VideoItem';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on Video': [
    'title',
    'text',
    {
      items: [
        {
          item: [videoItemQueryTemplate],
        },
      ],
    },
    'headingColor',
    'textColor',
    'backgroundColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      item: itemPropTypes,
    })
  ).isRequired,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  title: null,
  text: undefined,
  headingColor: null,
  textColor: null,
  backgroundColor: null,
  headingFont: null,
  textFont: null,
};

export default function Video({
  title,
  text,
  items,
  headingColor,
  textColor,
  backgroundColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <div className={styles.container}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {title && <h1 className={styles.heading}>{title}</h1>}
      {text && (
        <div className={styles.sec_text}>
          <MarkdownText text={text} />
        </div>
      )}
      <div className={styles.videos_container}>
        {items.map(({ item }, i) => (
          <Item key={i} {...item} />
        ))}
      </div>
    </div>
  );
}

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

import PropTypes from 'prop-types';
import Image from 'generic/Image';
import styles from 'generic/CarouselThumb.module.css';
import { FaVideo } from 'react-icons/fa';
import { useCallback } from 'react';

const propTypes = {
  onThumbClick: PropTypes.func.isRequired,
  thumbIndex: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  imgSrc: PropTypes.object,
  mediaAlt: PropTypes.string,
  showVideoIcon: PropTypes.bool,
};

const defaultProps = {
  imgSrc: null,
  mediaAlt: null,
  showVideoIcon: false,
};

export default function CarouselThumb({
  onThumbClick,
  thumbIndex,
  selected,
  imgSrc,
  mediaAlt,
  showVideoIcon,
}) {
  const handleThumbClick = useCallback(() => {
    onThumbClick(thumbIndex);
  }, [thumbIndex, onThumbClick]);

  return (
    <div
      className={`${styles.embla_slide} ${styles.embla_slide_thumb} ${
        selected ? styles.embla_is_selected : ''
      }`}
    >
      <button onClick={handleThumbClick} className={styles.embla_thumb_btn} type="button">
        {showVideoIcon && <FaVideo className={styles.video_icon} />}
        {imgSrc && (
          <Image
            className={styles.embla_slide_thumbnail}
            src={imgSrc}
            alt={mediaAlt}
            quality={25}
          />
        )}
      </button>
    </div>
  );
}

CarouselThumb.propTypes = propTypes;
CarouselThumb.defaultProps = defaultProps;

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { formatMinSecUnits, formatHrsMinSecUnits } from 'utils/format-time-left';
import calculateTimeLeft from 'utils/calculate-time-left';

const propTypes = {
  durationMinutes: PropTypes.number,
};

const defaultProps = {
  durationMinutes: null,
};

export default function CountdownClock({ durationMinutes }) {
  const [initialTime] = useState(Date.now());

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(durationMinutes, initialTime));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(durationMinutes, initialTime));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      {durationMinutes < 60 ? (
        <span>{` ${formatMinSecUnits(timeLeft)}`}</span>
      ) : (
        <span>{` ${formatHrsMinSecUnits(timeLeft)}`}</span>
      )}
    </>
  );
}

CountdownClock.propTypes = propTypes;
CountdownClock.defaultProps = defaultProps;

import styles from './LabelTimer.module.css';
import PropTypes from 'prop-types';
import { useHeadingFontOverrides } from 'utils/font-override';
import CountdownClock from './CountdownClock';
import { buildCssVar } from 'utils/style-override';

export const queryTemplate = {
  '... on LabelTimer': [
    'label',
    'duration',
    'labelColor',
    'timerColor',
    { headingFont: ['family'] },
  ],
};

const propTypes = {
  label: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  labelColor: PropTypes.string,
  timerColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  labelColor: null,
  timerColor: null,
  headingFont: null,
};

export default function LabelTimer({ label, duration, labelColor, timerColor, headingFont }) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--label-color', labelColor, 'var(--text-color-light)')}
          ${buildCssVar('--duration-color', timerColor, 'var(--text-color-light)')}
          ${headingFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.label_duration_wrapper}>
        <h4 className={styles.label}>{label}</h4>
        <div className={styles.duration}>
          <CountdownClock durationMinutes={duration} />
        </div>
      </div>
    </div>
  );
}

LabelTimer.propTypes = propTypes;
LabelTimer.defaultProps = defaultProps;

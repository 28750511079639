import { useCallback, useEffect, useRef, useState } from 'react';
import {
  CheckoutFinishUrlToken,
  CheckoutLocation,
  OrderPaymentMethod,
  TransactionErrorCode,
  TransactionErrorMessage,
} from 'constant';
import fetcher from './fetcher';
import { getGaClientId, getGa4SessionId } from './ga';
import { getAnonymousId, getSessionId, getUserTraits } from './rudderstack';
import * as gtm from './gtm';
import { finishOrder, redirectToOrderSummary } from './order';
import debounce from 'lodash/debounce';
import { sanitizeData } from './checkout-form';
import { identify as klaviyoIdentify, trackStartedCheckout } from './klaviyo';
import { matterIdentify } from './matter-identify';
import { parsePrice, priceToCents } from './price';

export const getPaypalOptions = cartData => ({
  // https://developer.paypal.com/docs/checkout/reference/customize-sdk
  clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
  currency: cartData?.data.currency,
  intent: 'capture',
  integrationDate: '2021-10-15',
  dataPartnerAttributionId: process.env.NEXT_PUBLIC_PAYPAL_PARTNER_ATTRIBUTION_ID,
  disableFunding:
    'card,credit,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo',
  locale: 'en_US',
  merchantId: cartData?.data.paypalMerchantId,
});

export const createPaymentIntent = async ({
  paymentProcessor,
  paymentMethod,
  apiBaseUrl,
  cartData,
  customerData,
  funnelId,
  adData,
  funnelName,
  ga4MeasurementId,
  source,
}) => {
  try {
    const result = await fetcher(`${apiBaseUrl}/orders`, {
      // Needed for CORS request to send cookies
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cartData,
        funnelId,
        paymentProcessor,
        paymentMethod,
        customerData,
        metadata: {
          ...adData,
          funnelId,
          funnelName,
          gaClientId: getGaClientId(),
          ga4SessionId: await getGa4SessionId(ga4MeasurementId),
          rudderAnonymousId: getAnonymousId(),
          rudderSessionId: getSessionId(),
          rudderUserTraits: getUserTraits(),
          rudderPageUrl: window.location.href,
          source,
        },
      }),
    });
    return result;
  } catch (err) {
    const errorResponse = { error: { statusCode: err.statusCode } };

    const errorCode = Object.values(TransactionErrorCode).find(code =>
      err?.data?.body?.includes(`"code":${code}`)
    );

    if (errorCode) {
      errorResponse.error.message = TransactionErrorMessage[errorCode];
    } else if (err.data?.body?.includes('ModelValidation')) {
      // Handle server model validation errors
      const body = JSON.parse(err.data.body);
      errorResponse.error.message = `Invalid ${Object.keys(body.data)[0]}.`;
    } else {
      errorResponse.error.message = TransactionErrorMessage.OTHER;
    }

    return errorResponse;
  }
};

export const buildSuccessUrl = ({ upsellUrl }) => {
  if (!upsellUrl) {
    // No downsellUrl set, so finish and redirect to order summary.
    return CheckoutFinishUrlToken;
  } else {
    // Go to next step in the funnel.
    return upsellUrl;
  }
};

export const success = ({
  orderId,
  authKey,
  cartData,
  upsellUrl,
  customerData,
  apiBaseUrl,
  funnelId,
  router,
  paymentMethod,
}) => {
  gtm.onPurchase({
    orderId,
    cartData,
    purchaseType: gtm.PURCHASE_TYPES.MAIN_OFFER,
    customerData,
    paymentMethod,
  });
  const url = buildSuccessUrl({ upsellUrl });

  if (url === CheckoutFinishUrlToken) {
    return finishOrder(orderId, authKey, apiBaseUrl, funnelId);
  } else {
    return router.push(url);
  }
};

export const useHandlePaymentRequestClick = ({
  isDisabled,
  paymentMethod,
  setDisabled,
  setShouldShowActivityIndicator,
  setFormErrorMessage,
  isFormValid,
  triggerValidation,
  trackPaymentClick,
}) => {
  // Handling disabled state here (see comment below).
  // Any asynchronous logic needs to happen after you have decided whether to show the form or not
  // and have called ev.preventDefault, if appropriate. See validation for an example.
  return ev => {
    // If button is disabled, don't show the payment interface.
    if (isDisabled) {
      // This will stop the payment interface from being shown.
      ev.preventDefault();
      return;
    }

    // If form data is invalid, don't show the payment interface, and trigger validation
    if (!isFormValid) {
      ev.preventDefault();
      return triggerValidation();
    }

    // Show the payment interface
    setDisabled(true);
    setShouldShowActivityIndicator(true);
    setFormErrorMessage(null);
    trackPaymentClick(paymentMethod);
  };
};

export const useHandlePayPalOnClick = ({
  isDisabled,
  triggerValidation,
  setShouldShowActivityIndicator,
  setFormErrorMessage,
  trackPaymentClick,
}) => {
  const payPalOnClickRef = useRef({
    isDisabled,
    triggerValidation,
    setFormErrorMessage,
    setShouldShowActivityIndicator,
    trackPaymentClick,
  });
  useEffect(() => {
    payPalOnClickRef.current = {
      isDisabled,
      triggerValidation,
      setFormErrorMessage,
      setShouldShowActivityIndicator,
      trackPaymentClick,
    };
  }, [
    isDisabled,
    triggerValidation,
    setFormErrorMessage,
    setShouldShowActivityIndicator,
    trackPaymentClick,
  ]);

  // PayPal callback functions only bind once so we can't rely on React handling the binding
  return async (_, actions) => {
    // Ignore click if disabled
    if (payPalOnClickRef.current.isDisabled) {
      return actions.reject();
    }

    payPalOnClickRef.current.setFormErrorMessage(null);
    payPalOnClickRef.current.setShouldShowActivityIndicator(true);
    const isValid = await payPalOnClickRef.current.triggerValidation();
    if (!isValid) {
      payPalOnClickRef.current.setShouldShowActivityIndicator(false);
      return actions.reject();
    }

    payPalOnClickRef.current.trackPaymentClick(OrderPaymentMethod.PAYPAL);
    return actions.resolve();
  };
};

// Handler for error form with View Order Summary button
export const useHandleViewOrderSummary = ({
  setShouldShowActivityIndicator,
  modalError,
  apiBaseUrl,
  funnelId,
}) => {
  const [isViewOrderSummaryDisabled, setViewOrderSummaryDisabled] = useState(false);

  const handleViewOrderSummary = useCallback(async () => {
    try {
      setShouldShowActivityIndicator(true);
      setViewOrderSummaryDisabled(true);
      if (modalError?.shouldComplete) {
        await finishOrder(modalError?.orderId, modalError?.authKey, apiBaseUrl, funnelId);
      } else {
        await redirectToOrderSummary(modalError?.orderId, modalError?.authKey, funnelId);
      }
    } catch (err) {
      console.log(err);
      setViewOrderSummaryDisabled(false);
      setShouldShowActivityIndicator(false);
    }
  }, [
    modalError?.shouldComplete,
    modalError?.orderId,
    modalError?.authKey,
    funnelId,
    apiBaseUrl,
    setShouldShowActivityIndicator,
  ]);

  return { handleViewOrderSummary, isViewOrderSummaryDisabled };
};

export const getIdentifyOnBlur = ({
  funnelId,
  getValues,
  trigger,
  cartData,
  cartIsReady,
  offersConfig,
  klaviyoPublicKey,
  apiBaseUrl,
  hasStartedCheckout,
  setHasStartedCheckout,
  paymentMethod,
}) =>
  debounce(async ev => {
    const subscribeSms = ev.target.id === 'phone' || ev.target.id === 'phoneConsent';
    const { email, phone, ...data } = sanitizeData(getValues());
    // Since validation also happens on blur, there is a race condition here that means
    // the last round of validation will not have been run before this function is executed.
    // In order to check that the email passes validation before identifying, we need to
    // validate manually.
    const emailIsValid = !!email && (await trigger('email'));
    if (emailIsValid) data.email = email;

    const phoneIsValid = !!phone && (await trigger('phone'));
    if (phoneIsValid) data.phone = phone;

    if (!emailIsValid && !phoneIsValid) return;

    if (window._learnq && window._learnq.isIdentified && window._learnq.isIdentified() !== true) {
      klaviyoIdentify(data);
    }

    let customerLead = {};

    try {
      customerLead = await matterIdentify({
        customerData: data,
        apiBaseUrl,
        funnelId,
        subscribeSms,
      });
    } catch (e) {
      console.error(e);
    }

    // We only want to track one started checkout event per session
    if (!hasStartedCheckout && cartIsReady) {
      gtm.onAddContactInfo({
        cartData,
        paymentMethod,
        checkoutLocation: CheckoutLocation.CHECKOUT_PAGE,
        email,
        leadId: customerLead?.id,
      }); // only fire once
      await trackStartedCheckout(
        { email, phone },
        cartData,
        offersConfig,
        funnelId,
        klaviyoPublicKey
      );
      setHasStartedCheckout(true);
    }
  }, 250);

// Returns an array of display items, used for payment request config.
export function buildDisplayItems(cartData) {
  return cartData?.data.lineItems.map(lineItem => {
    const label = `${lineItem.name}${lineItem.quantity > 1 ? ` x ${lineItem.quantity}` : ''}`;
    // This amount is not using originalPrice because it includes discount if applicable
    const amountDollars = (parsePrice(lineItem.price) * lineItem.quantity).toFixed(2);
    return {
      label,
      amount: Number(priceToCents(amountDollars)),
    };
  });
}

import PropTypes from 'prop-types';

import usePageType from 'hooks/usePageType';

import { queryTemplate as multiVariantBundleItemQueryTemplate } from './MultiVariantBundleItem';
import MultiVariantBundleItem, { itemPropTypes } from './MultiVariantBundleItem';
import EnhancedBundleItem from './UpsellComponents/EnhancedBundleItem';

import styles from './MultiVariantBundle.module.css';

export const queryTemplate = {
  '... on MultiVariantBundle': [
    {
      bundles: [
        {
          item: [multiVariantBundleItemQueryTemplate],
        },
      ],
    },
    'sectionId',
  ],
};

const propTypes = {
  bundles: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape(itemPropTypes).isRequired,
    })
  ).isRequired,
  sectionId: PropTypes.string.isRequired,
};

const defaultProps = {};

export default function MultiVariantBundle({ bundles, sectionId }) {
  const { isUpsellPage } = usePageType();

  return (
    <div id={sectionId} className={styles.container}>
      {bundles?.map(({ item }, i) =>
        isUpsellPage ? (
          <EnhancedBundleItem {...item} key={i} />
        ) : (
          <MultiVariantBundleItem {...item} key={i} />
        )
      )}
    </div>
  );
}

MultiVariantBundle.propTypes = propTypes;
MultiVariantBundle.defaultProps = defaultProps;

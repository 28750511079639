export default function calculateTimeLeft(durationMinutes, initialTime) {
  const diff = initialTime + durationMinutes * 60 * 1000 - Date.now();
  const timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (diff > 0) {
    timeLeft.seconds = Math.floor(diff / 1000) % 60;
    timeLeft.minutes = Math.floor(diff / 1000 / 60) % 60;
    timeLeft.hours = Math.floor(diff / (1000 * 60 * 60)) % 24;
    timeLeft.days = Math.floor(diff / (1000 * 60 * 60 * 24));
  }

  return timeLeft;
}

import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import useEmblaCarousel from 'embla-carousel-react';
import { PrevButton, NextButton } from 'generic/CarouselButtons';
import { buildCssVar } from 'utils/style-override';
import styles from './FunnelCartCarousel.module.css';
import buttonCustomStyles from './FunnelCartCarouselButton.module.css';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.element),
  buttonColor: PropTypes.string,
};

const defaultProps = {
  items: null,
  buttonColor: null,
};

const FunnelCartCarousel = ({ items, buttonColor }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    align: 'center',
    slidesToScroll: 'auto',
    breakpoints: { '(max-width: 980px)': { draggable: true } },
    draggable: false,
    containScroll: 'trimSnaps',
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollNext = useCallback(() => {
    if (!embla) return;
    embla.scrollNext();
  }, [embla]);

  const scrollPrev = useCallback(() => {
    if (!embla) return;
    embla.scrollPrev();
  }, [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  return (
    <div className={`root ${styles.embla}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--button-color', buttonColor, '#8f8e8d')}
        }
      `}</style>

      <div className={styles.embla__viewport} ref={viewportRef}>
        <div className={styles.embla__container}>
          {items.map((item, i) => (
            <div className={styles.embla__slide} key={i}>
              <div className={`${styles.embla__slide__inner}`}>{item}</div>
            </div>
          ))}
        </div>
      </div>
      <PrevButton
        onClick={scrollPrev}
        enabled={prevBtnEnabled}
        hideOnMobile={true}
        customStyles={buttonCustomStyles}
      />
      <NextButton
        onClick={scrollNext}
        enabled={nextBtnEnabled}
        hideOnMobile={true}
        customStyles={buttonCustomStyles}
      />
    </div>
  );
};

FunnelCartCarousel.prototype = propTypes;
FunnelCartCarousel.defaultProps = defaultProps;

export default FunnelCartCarousel;

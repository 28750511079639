import styles from './CountdownBanner.module.css';
import PropTypes from 'prop-types';
import LabelText, { queryTemplate as labelTextQueryTemplate } from './LabelText';
import LabelTimer, { queryTemplate as labelTimerQueryTemplate } from './LabelTimer';
import { buildCssVar } from 'utils/style-override';

export const queryTemplate = {
  '... on CountdownBanner': [
    {
      items: [
        'id',
        'collection',
        {
          item: [labelTextQueryTemplate, labelTimerQueryTemplate],
        },
      ],
    },
    'backgroundColor',
  ],
};

const bannerComponents = {
  LabelText,
  LabelTimer,
};

const bannerItemPropTypes = {
  item: PropTypes.oneOfType([
    PropTypes.shape(LabelText.propTypes),
    PropTypes.shape(LabelTimer.propTypes),
  ]),
};

const bannerItemDefaultProps = {};

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(bannerItemPropTypes)).isRequired,
  backgroundColor: PropTypes.string,
};

const defaultProps = {
  backgroundColor: null,
};

const BannerItemComponent = item => {
  const BannerComponent = bannerComponents[item.collection];
  if (!BannerComponent) return null;

  return <BannerComponent {...item.item} />;
};

BannerItemComponent.propTypes = bannerItemPropTypes;
BannerItemComponent.defaultProps = bannerItemDefaultProps;

export default function CountdownBanner({ backgroundColor, items }) {
  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'var(--primary-color)')}
        }
      `}</style>
      {items.map(item => (
        <BannerItemComponent {...item} key={`${item.collection}${item.id}`} />
      ))}
    </div>
  );
}

CountdownBanner.propTypes = propTypes;
CountdownBanner.defaultProps = defaultProps;

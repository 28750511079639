import Image from './Image';
import styles from './FeaturedIn.module.css';
import PropTypes from 'prop-types';
import { useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

export const queryTemplate = {
  '... on FeaturedIn': [
    'title',
    {
      images: [
        {
          item: [
            {
              '... on FeaturedInImage': [{ image: ['...FilePartsWithMetadata'] }, 'imageAlt'],
            },
          ],
        },
      ],
    },
    'headingColor',
    'backgroundColor',
    { headingFont: ['family'] },
  ],
};

const propTypes = {
  title: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        image: PropTypes.object.isRequired,
        imageAlt: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  headingColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  title: null,
  headingColor: null,
  backgroundColor: null,
  headingFont: null,
};

export default function FeaturedIn({ title, images, headingColor, backgroundColor, headingFont }) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${headingFontOverrides ?? ''}
        }
      `}</style>

      {title && <h1 className={styles.heading}>{title}</h1>}
      <div className={styles.featured_in}>
        {images.map(({ item }, index) => {
          return (
            <div className={styles.image_wrapper} key={index}>
              <Image
                src={item.image}
                alt={item.imageAlt}
                width={150}
                height={150}
                placeholder="blur"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

FeaturedIn.propTypes = propTypes;
FeaturedIn.defaultProps = defaultProps;

import { SlBadge } from 'react-icons/sl';
import { FaShippingFast, FaFlagUsa } from 'react-icons/fa';
import { BsClipboardCheck } from 'react-icons/bs';
import { GiTestTubes, GiEcology } from 'react-icons/gi';
import { RiPlantFill } from 'react-icons/ri';

const IconsList = {
  badge: <SlBadge />,
  freeShipping: <FaShippingFast />,
  clipboard: <BsClipboardCheck />,
  beakers: <GiTestTubes />,
  usaFlag: <FaFlagUsa />,
  plantBased: <RiPlantFill />,
  ecoFriendly: <GiEcology />,
};

export default IconsList;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './FunnelCartQuantitySelector.module.css';
import { useCart } from 'utils/cart-context';
import { onSelectItem } from 'utils/gtm';
import * as rudder from 'utils/rudderstack';

const productPropTypes = {};

const productOfferPropTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    maxQuantity: PropTypes.number.isRequired,
  }),
};

const propTypes = {
  product: PropTypes.shape({ ...productPropTypes, ...productOfferPropTypes }).isRequired,
};

const defaultProps = {};

function FunnelCartQuantitySelector({ product }) {
  const offerId = product.offer?.id;
  const maxQuantity = product.offer?.maxQuantity;
  const { updateCartItem } = useCart();
  const [quantity, setQuantity] = useState(1);

  // If product changes, set to default 1
  useEffect(() => setQuantity(1), [product]);

  const decrement = () => {
    if (!offerId) return;

    const newQuantity = quantity - 1;
    if (newQuantity < 1) return;
    updateCartItem({ offerId, quantity: newQuantity });
    setQuantity(newQuantity);
    onSelectItem({ ...product.offer, quantity: newQuantity });
    rudder.onSelectItem({ ...product.offer, quantity: newQuantity });
  };

  const increment = () => {
    if (!offerId) return;

    const newQuantity = quantity + 1;
    if (newQuantity > maxQuantity) return;
    updateCartItem({ offerId, quantity: newQuantity });
    setQuantity(newQuantity);
    onSelectItem({ ...product.offer, quantity: newQuantity });
    rudder.onSelectItem({ ...product.offer, quantity: newQuantity });
  };

  return (
    <div className={styles.main}>
      <h2 className={styles.heading}>Quantity</h2>
      <div className={styles.selector_wrapper}>
        <button className={styles.quantity_btn} onClick={decrement} disabled={quantity === 1}>
          &#8722;
        </button>
        <p className={styles.quantity}>{quantity}</p>
        <button
          className={styles.quantity_btn}
          onClick={increment}
          disabled={quantity === maxQuantity}
        >
          &#xFF0B;
        </button>
      </div>
    </div>
  );
}

export default FunnelCartQuantitySelector;

FunnelCartQuantitySelector.propTypes = propTypes;
FunnelCartQuantitySelector.defaultProps = defaultProps;

import PropTypes from 'prop-types';
import { buildCssVar } from 'utils/style-override';
import { useMemo } from 'react';
import AutoplayCarouselTheme1 from './AutoplayCarousel.theme1';
import BlazeImageCarousel from './BlazeImageCarousel';
import styles from './ImageCarousel.module.css';

export const queryTemplate = {
  '... on ImageCarousel': [
    {
      images: [
        {
          item: [
            {
              '... on ImageCarouselImage': [
                { image: ['...FilePartsWithMetadata'] },
                { placeholderImage: ['...FilePartsWithMetadata'] },
                { video: ['...FileParts'] },
                'showVideoControls',
                'mediaAlt',
              ],
            },
          ],
        },
      ],
    },
    'showThumbnailsOnDesktop',
    'imageProgressDisplayOnMobile',
    'backgroundColor',
    'buttonColor',
  ],
};

const propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        image: PropTypes.object,
        mediaAlt: PropTypes.string,
        video: PropTypes.object,
        showVideoControls: PropTypes.bool,
        placeholderImage: PropTypes.object,
      }),
    })
  ).isRequired,
  showThumbnailsOnDesktop: PropTypes.bool,
  imageProgressDisplayOnMobile: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  buttonColor: PropTypes.string,
};

const defaultProps = {
  images: [],
  showThumbnailsOnDesktop: false,
  backgroundColor: null,
  buttonColor: null,
};

export default function ImageCarousel({
  images,
  showThumbnailsOnDesktop,
  imageProgressDisplayOnMobile,
  backgroundColor,
  buttonColor,
}) {
  const hasVideos = useMemo(() => images.some(({ item }) => !!item.video), [images]);
  const showNewImageCarousel =
    !hasVideos && process.env.NEXT_PUBLIC_SHOW_NEW_IMAGE_CAROUSEL === 'true';

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
        }
      `}</style>
      {showNewImageCarousel && <BlazeImageCarousel images={images} />}
      {!showNewImageCarousel && (
        <AutoplayCarouselTheme1
          showButtons
          buttonColor={buttonColor}
          showDots
          shouldLoop
          shouldAutoplay={false}
          isImageCarousel={true}
          items={images}
          showThumbnails={showThumbnailsOnDesktop}
          imageProgressDisplayOnMobile={imageProgressDisplayOnMobile}
        />
      )}
    </div>
  );
}

ImageCarousel.propTypes = propTypes;
ImageCarousel.defaultProps = defaultProps;

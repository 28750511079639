import { buildFunnelPath } from './url';

export function redirectToOrderSummary(orderId, authKey, funnelId) {
  const orderSummaryUrl = `/orders/${orderId}/authenticate?key=${authKey}`;
  window.location.assign(funnelId ? buildFunnelPath(orderSummaryUrl, funnelId) : orderSummaryUrl);
}

export async function sendOrderComplete(orderId, apiBaseUrl) {
  return fetch(
    `${apiBaseUrl ?? process.env.NEXT_PUBLIC_API_BASE_URL}/complete?orderId=${orderId}`,
    {
      // Needed for CORS request to send cookies
      credentials: 'include',
      method: 'PUT',
    }
  );
}

// Hits the api to complete the order and redirects to the order summary page.
export async function finishOrder(orderId, authKey, apiBaseUrl, funnelId) {
  await sendOrderComplete(orderId, apiBaseUrl);
  redirectToOrderSummary(orderId, authKey, funnelId);
}

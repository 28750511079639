import { useEffect, useMemo, useState } from 'react';
import styles from './MiniCart.module.css';
import PropTypes from 'prop-types';
import { useFunnelData } from 'utils/funnel-data-context';
import { usePreviewData } from 'utils/preview-data-context';
import { useOnCheckoutClick } from 'utils/checkout-link';
import { useTrackViewedProduct } from 'utils/klaviyo';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { missingPageOffer } from 'utils/error';
import { buildCssVar } from 'utils/style-override';
import Spinner from './Spinner';

export const queryTemplate = {
  '... on MiniCart': [
    'shippingText',
    'ctaText',
    'ctaSubtext',
    { product: ['id', 'type', 'name'] },
    'productInfoTextColor',
    'productInfoBackgroundColor',
    'buttonTextColor',
    'buttonBackgroundColor',
    'buttonHoverColor',
    'buttonHoverTextColor',
    'buttonBorderColor',
    'buttonHoverBorderColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  shippingText: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaSubtext: PropTypes.string,
  product: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  productInfoTextColor: PropTypes.string,
  productInfoBackgroundColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  buttonHoverTextColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonHoverBorderColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  product: {},
  shippingText: null,
  ctaSubtext: null,
  productInfoTextColor: null,
  productInfoBackgroundColor: null,
  buttonTextColor: null,
  buttonBackgroundColor: null,
  buttonHoverColor: null,
  buttonHoverTextColor: null,
  buttonBorderColor: null,
  buttonHoverBorderColor: null,
  headingFont: null,
  textFont: null,
};

export default function MiniCart({
  shippingText,
  ctaText,
  ctaSubtext,
  product,
  productInfoTextColor,
  productInfoBackgroundColor,
  buttonTextColor,
  buttonBackgroundColor,
  buttonHoverColor,
  buttonHoverTextColor,
  buttonBorderColor,
  buttonHoverBorderColor,
  headingFont,
  textFont,
}) {
  const { preview } = usePreviewData();
  useEffect(() => {
    if (!product.id) {
      const message = 'No directus Product found for MiniCart component';
      if (!preview) throw new Error(message);
      console.warn(message);
    }
  });

  useEffect(() => {
    if (product.type !== 'miniCart') {
      console.error(`Incorrect Product type for Mini Cart: Product must be of type "Mini Cart"`, {
        id: product.id,
        type: product.type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { offerData, offersConfig } = useFunnelData();
  const [shouldShowActivityIndicator, setShouldShowActivityIndicator] = useState(false);

  const { offerConfig, offer } = useMemo(() => {
    const config = offersConfig.find(el => el.directusProductId === product.id);
    return {
      offerConfig: config,
      offer: offerData.data.find(el => el.id === config?.id),
    };
  }, [offersConfig, offerData.data, product.id]);

  // Offer/Directus Product mapping validation
  useEffect(() => {
    if (!offerConfig) missingPageOffer(product.id, preview);
  }, [offerConfig, product.id, preview]);

  useTrackViewedProduct(offer, offerConfig);

  const getCheckoutClickHandler = useOnCheckoutClick({ setShouldShowActivityIndicator });

  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar(
            '--product-info-text-color',
            productInfoTextColor,
            'var(--text-color-dark)'
          )}
          ${buildCssVar(
            '--product-info-background-color',
            productInfoBackgroundColor,
            'var(--secondary-color)'
          )}
          ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-light)')}
          ${buildCssVar('--button-background-color', buttonBackgroundColor, 'var(--primary-color)')}
          ${buildCssVar('--button-hover-color', buttonHoverColor, 'var(--primary-color)')}
          ${buildCssVar(
            '--button-hover-text-color',
            buttonHoverTextColor,
            'var(--text-color-light)'
          )}
          ${buildCssVar(
            '--button-border-color',
            buttonBorderColor,
            buttonBackgroundColor,
            'var(--primary-color)'
          )}
          ${buildCssVar(
            '--button-hover-border-color',
            buttonHoverBorderColor,
            buttonHoverColor,
            'var(--primary-color)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {shouldShowActivityIndicator && <Spinner />}
      <div className={styles.product_info_container}>
        <div className={styles.product_info}>
          <div className={styles.mini_cart}>
            <h2 className={styles.product_name}>{product.name || offer?.name}</h2>
            <div className={styles.price_wrapper}>
              <h2 className={styles.price}>${offer?.price}</h2>
              <h4>{shippingText}</h4>
            </div>
          </div>
        </div>

        <button
          className={styles.cta_button}
          onClick={getCheckoutClickHandler([{ offerId: offer?.id, quantity: 1 }])}
        >
          <p className={styles.cta_text}>{ctaText}</p>
          {ctaSubtext && <p>{ctaSubtext}</p>}
        </button>
      </div>
    </div>
  );
}

MiniCart.propTypes = propTypes;
MiniCart.defaultProps = defaultProps;

export function getGaClientId() {
  try {
    return window.ga.getAll()[0].get('clientId');
  } catch {
    return;
  }
}

export async function getGa4SessionId(ga4MeasurementId) {
  if (!ga4MeasurementId) return;

  try {
    return getCookie(`_ga_${ga4MeasurementId.substring(2)}`).split('.')[2];
  } catch {
    return;
  }
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

import { buildCartQuery } from './cart';
import { buildFunnelPath } from './url';

export function buildReturnUrl({
  successUrl,
  failUrl,
  funnelId,
  cartItems,
  discountCode,
  isUpsell = false,
}) {
  const url = `${window.location.origin}${buildFunnelPath('/afterpay-return', funnelId)}`;
  const cart = buildCartQuery(cartItems);
  const sUrl = encodeURIComponent(successUrl);
  const fUrl = encodeURIComponent(failUrl);

  return `${url}&cart=${cart}&${
    discountCode ? `code=${discountCode}&` : ''
  }surl=${sUrl}&furl=${fUrl}&isup=${isUpsell}`;
}

import PropTypes from 'prop-types';
import Image from './Image';
import styles from './SocialReview.theme1.module.css';
import { reviewPropTypes } from 'utils/prop-types';
import { FaCheckCircle } from 'react-icons/fa';
import RatingStars from './RatingStars';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

export const queryTemplate = {
  '... on TestimonialReview': [
    'userName',
    { userImage: ['...FilePartsWithMetadata'] },
    'title',
    'text',
    'rating',
    'userNameColor',
    'headingColor',
    'textColor',
    'backgroundColor',
    'verifiedColor',
    'starColor',
    'starEmptyColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  review: PropTypes.shape({ item: PropTypes.shape(reviewPropTypes) }),
  showHeader: PropTypes.bool,
  containerStyle: PropTypes.string,
  headerStyle: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  review: {},
  showHeader: false,
  containerStyle: null,
  headerStyle: null,
};

function SocialReview({ review, showHeader, containerStyle, headerStyle }) {
  const {
    item: {
      userName,
      userImage,
      title,
      text,
      rating,
      userNameColor,
      headingColor,
      textColor,
      backgroundColor,
      verifiedColor,
      starColor,
      starEmptyColor,
      headingFont,
      textFont,
    },
  } = review;

  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container} ${containerStyle}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--user-name-color', userNameColor, 'var(--text-color-dark)')}
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--secondary-color)')}
          ${buildCssVar('--verified-color', verifiedColor, 'var(--color-positive)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {showHeader && (
        <div className={`${styles.header_container} ${headerStyle}`}>
          <div className={styles.user_name}>
            <p>{userName}</p>
            {!!rating && (
              <div className={styles.icon_wrapper}>
                <RatingStars
                  rating={rating}
                  starColor={starColor}
                  starEmptyColor={starEmptyColor}
                />
              </div>
            )}
          </div>
          <div className={styles.verified_container}>
            <div className={styles.verified_icon}>
              <FaCheckCircle />
            </div>
            <p>VERIFIED BUYER</p>
          </div>
        </div>
      )}
      {userImage && (
        <div className={styles.img_wrapper}>
          <Image src={userImage} alt="User Image" placeholder="blur" />
        </div>
      )}
      <div className={styles.review_body}>
        <h5>{title}</h5>
        <p>{text}</p>
      </div>
    </div>
  );
}

SocialReview.prototype = propTypes;
SocialReview.defaultProps = defaultProps;

export default SocialReview;

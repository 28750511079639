import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import styles from './Story.module.css';
import Item, { queryTemplate as storyItemQueryTemplate } from './StoryItem';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on Story': [
    'title',
    'text',
    {
      items: [
        {
          item: [storyItemQueryTemplate],
        },
      ],
    },
    'headingColor',
    'textColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape(Item.propTypes),
    })
  ),
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  title: null,
  text: undefined,
  items: [],
  headingColor: null,
  textColor: null,
  headingFont: null,
  textFont: null,
};

export default function Story({
  title,
  text,
  items,
  headingColor,
  textColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.founders_story}>
        {title && <h1 className={`${styles.heading} ${styles.founders_title}`}>{title}</h1>}
        {text && (
          <div className={styles.subheading}>
            <MarkdownText text={text} />
          </div>
        )}
        {items.length && (
          <div className={styles.cards_wrapper}>
            {items.map(({ item }, i) => (
              <Item key={i} {...item} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

Story.propTypes = propTypes;
Story.defaultProps = defaultProps;

import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useHeadingFontOverrides, useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';
import { queryTemplate as accordionItemQueryTemplate } from './AccordionItem.theme1';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on Accordion': [
    'sectionId',
    'title',
    'tagline',
    'text',
    {
      items: [
        {
          item: [accordionItemQueryTemplate],
        },
      ],
    },
    'headingColor',
    'backgroundColor',
    { headingFont: ['family'] },
    'taglineColor',
    'textColor',
    { textFont: ['family'] },
    'itemTextColor',
    'itemLinkTextColor',
    'itemBackgroundColor',
    { itemHeadingFont: ['family'] },
    { itemTextFont: ['family'] },
  ],
};

const propTypes = {
  sectionId: PropTypes.string,
  title: PropTypes.string,
  tagline: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  headingColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  taglineColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
  itemLinkTextColor: PropTypes.string,
  itemTextColor: PropTypes.string,
  itemBackgroundColor: PropTypes.string,
  itemHeadingFont: PropTypes.shape({ family: PropTypes.string }),
  itemTextFont: PropTypes.shape({ family: PropTypes.string }),
  AccordionItem: PropTypes.elementType.isRequired,
  styles: PropTypes.object.isRequired,
};

const defaultProps = {
  sectionId: null,
  title: null,
  tagline: null,
  text: null,
  headingColor: null,
  backgroundColor: null,
  headingFont: null,
  taglineColor: null,
  textColor: null,
  textFont: null,
  itemLinkTextColor: null,
  itemTextColor: undefined,
  itemBackgroundColor: undefined,
  itemHeadingFont: null,
  itemTextFont: null,
};

export default function Accordion({
  sectionId,
  title,
  tagline,
  text,
  formattedText,
  items,
  headingColor,
  backgroundColor,
  headingFont,
  taglineColor,
  textColor,
  textFont,
  itemLinkTextColor,
  itemTextColor,
  itemBackgroundColor,
  itemHeadingFont,
  itemTextFont,
  AccordionItem,
  styles,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const rootClassNames = ['root', styles.container];
  if (backgroundColor) rootClassNames.push(styles.top_bottom_padding);

  return (
    <div id={sectionId} className={rootClassNames.join(' ')}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar('--tagline-color', taglineColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${headingFontOverrides ?? ''};
          ${textFontOverrides ?? ''};
        }
      `}</style>

      <div className={styles.sec_wrapper}>
        {tagline && <p className={styles.tagline}>{tagline}</p>}
        {title && <h1 className={styles.heading}>{title}</h1>}
        {text && (
          <div className={styles.text}>
            {!formattedText && <MarkdownText text={text}></MarkdownText>}
            {formattedText && <div dangerouslySetInnerHTML={{ __html: formattedText }} />}
          </div>
        )}

        <div className={styles.items_wrapper}>
          {items.map(({ item }, i) => (
            <AccordionItem
              key={i}
              textColor={itemTextColor}
              linkTextColor={itemLinkTextColor}
              backgroundColor={itemBackgroundColor}
              headingFont={itemHeadingFont}
              textFont={itemTextFont}
              {...item}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

Accordion.propTypes = propTypes;
Accordion.defaultProps = defaultProps;

import React, { useEffect, useCallback, useState } from 'react';
import styles from './AutoplayCarousel.theme1.module.css';
import PropTypes from 'prop-types';
import useEmblaCarousel from 'embla-carousel-react';
import { PrevButton, NextButton, DotButton } from 'generic/CarouselButtons';
import { useRecursiveTimeout } from 'utils/useRecursiveTimeout';
import { buildCssVar } from 'utils/style-override';
import LazyAutoplayVideo from './LazyAutoplayVideo';
import Image from './Image';
import CarouselThumb from 'generic/CarouselThumb';

const AUTOPLAY_INTERVAL = 3000;

const ProgressDiaply = {
  DOTS: 'dots',
  IMAGE_COUNT: 'imageCount',
};

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.element),
  buttonColor: PropTypes.string,
  showButtons: PropTypes.bool,
  showDots: PropTypes.bool,
  showThumbnails: PropTypes.bool,
  imageProgressDisplayOnMobile: PropTypes.string,
  shouldAutoplay: PropTypes.bool,
  shouldLoop: PropTypes.bool,
  alignment: PropTypes.string,
  isImageCarousel: PropTypes.bool,
};

const defaultProps = {
  items: null,
  buttonColor: null,
  showButtons: false,
  showDots: false,
  showThumbnails: false,
  imageProgressDisplayOnMobile: ProgressDiaply.DOTS,
  shouldAutoplay: true,
  shouldLoop: false,
  alignment: 'center',
  isImageCarousel: false,
};

const AutoplayCarouselTheme1 = ({
  items,
  buttonColor,
  showButtons,
  showDots,
  showThumbnails,
  imageProgressDisplayOnMobile,
  shouldAutoplay,
  shouldLoop,
  alignment,
  isImageCarousel,
}) => {
  const [viewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    loop: shouldLoop,
    align: alignment,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    selectedClass: '',
    dragFree: true,
  });

  const autoplay = useCallback(() => {
    if (!embla) return;
    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
  }, [embla]);

  const { play, stop } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL);

  const scrollTo = useCallback(
    i => {
      if (!embla) return;
      embla.scrollTo(i);
      stop();
    },
    [embla, stop]
  );

  const scrollNext = useCallback(() => {
    if (!embla) return;
    embla.scrollNext();
    setSelectedIndex(embla.selectedScrollSnap());
    stop();
  }, [embla, stop]);

  const scrollPrev = useCallback(() => {
    if (!embla) return;
    embla.scrollPrev();
    setSelectedIndex(embla.selectedScrollSnap());
    stop();
  }, [embla, stop]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  const onThumbClick = useCallback(
    index => {
      if (!embla || !emblaThumbs) return;
      if (emblaThumbs.clickAllowed()) embla.scrollTo(index);
    },
    [embla, emblaThumbs]
  );

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
    embla.on('pointerDown', stop);
  }, [embla, onSelect, stop]);

  useEffect(() => {
    if (shouldAutoplay) play();
  }, [play, shouldAutoplay]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <div className={`root ${styles.embla}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--button-color', buttonColor, '#8f8e8d')}
        }
      `}</style>

      <div className={styles.embla__viewport} ref={viewportRef}>
        <div className={styles.embla__container}>
          {isImageCarousel &&
            items.map(
              ({ item: { image, video, showVideoControls, placeholderImage, mediaAlt } }, i) => {
                return (
                  <div className={`${styles.embla__slide} ${styles.img_carousel_slide}`} key={i}>
                    <div className={`${styles.embla__slide__inner} embla_slide`}>
                      {image && (
                        <Image
                          alt={mediaAlt}
                          src={image}
                          priority={i === 0}
                          placeholder="blur"
                          sizes="670px"
                        />
                      )}
                      {video && (
                        <LazyAutoplayVideo
                          alt={mediaAlt}
                          placeholderImage={placeholderImage}
                          video={video}
                          showVideoControls={showVideoControls}
                          shouldPlay={selectedIndex === i}
                        />
                      )}
                    </div>
                  </div>
                );
              }
            )}
          {!isImageCarousel &&
            items.map((item, i) => {
              return (
                <div className={styles.embla__slide} key={i}>
                  <div className={`${styles.embla__slide__inner} embla_slide`}>{item}</div>
                </div>
              );
            })}
        </div>
        {imageProgressDisplayOnMobile === ProgressDiaply.IMAGE_COUNT && items.length > 0 && (
          <div className={styles.image_count}>{`${selectedIndex + 1} / ${items.length}`}</div>
        )}
        {showButtons && (
          <>
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
          </>
        )}
      </div>
      {showDots && (
        <div
          className={`${styles.embla__dots} ${showThumbnails ? styles.hide_dots_on_desktop : ''} ${
            imageProgressDisplayOnMobile === ProgressDiaply.IMAGE_COUNT
              ? styles.hide_dots_on_mobile
              : ''
          }`}
        >
          {scrollSnaps.map((_, i) => (
            <div key={i}>
              <DotButton key={i} isSelected={i === selectedIndex} onClick={() => scrollTo(i)} />
            </div>
          ))}
        </div>
      )}
      {showThumbnails && (
        <div className={styles.embla_thumb}>
          <div className={styles.embla__viewport} ref={thumbViewportRef}>
            <div className={styles.embla_container_thumb}>
              {items.map(({ item: { image, mediaAlt, video, placeholderImage } }, i) => (
                <CarouselThumb
                  key={i}
                  onThumbClick={onThumbClick}
                  thumbIndex={i}
                  selected={i === selectedIndex}
                  imgSrc={image ?? placeholderImage}
                  alt={mediaAlt}
                  showVideoIcon={!!video}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AutoplayCarouselTheme1.prototype = propTypes;
AutoplayCarouselTheme1.defaultProps = defaultProps;

export default AutoplayCarouselTheme1;

const queryTemplate = {
  '... on Banner': [
    'text',
    'textColor',
    'backgroundColor',
    {
      headingFont: ['family'],
    },
  ],
};

export default queryTemplate;

import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useFunnelData } from 'utils/funnel-data-context';
import { usePreviewData } from 'utils/preview-data-context';
import FunnelCartProduct, {
  productPropTypes,
  queryTemplate as itemQueryTemplate,
} from './FunnelCartProduct';
import FunnelCartBuyFrom from './FunnelCartBuyFrom';
import AfterpayWidget, {
  itemPropTypes,
  queryTemplate as afterpayWidgetQueryTemplate,
} from './AfterpayWidget';
import { missingPageOffer } from 'utils/error';
import { trackViewedProduct } from 'utils/klaviyo';
import { useHeadingFontOverrides, useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';
import FunnelCartCarousel from './FunnelCartCarousel';
import styles from './FunnelCart.module.css';
import { BuyFromType, BuyFromAvailability } from 'constant';
import FunnelCartSaveThis from './FunnelCartSaveThis';
import FunnelCartQuantitySelector from './FunnelCartQuantitySelector';

// Load stickyBuyButton dynamically
const StickyBuyButton = dynamic(() => import('./StickyBuyButton'), { ssr: false });

export const queryTemplate = {
  '... on FunnelCart': [
    {
      products: [
        {
          item: [itemQueryTemplate],
        },
      ],
    },
    { afterpayWidget: afterpayWidgetQueryTemplate },
    { buyFromBrandLogo: ['...FilePartsWithMetadata'] },
    'buyFromBrandName',
    'quantitySelector',
    'saveThis',
    'showProductUnitPrice',
    'defaultPaymentOption',
    'headingColor',
    'buttonColor',
    'buttonTextColor',
    'buttonBorderColor',
    'buttonHoverColor',
    'buttonHoverTextColor',
    'buttonHoverBorderColor',
    'buyButtonBannerBackgroundColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape(productPropTypes).isRequired,
    })
  ).isRequired,
  afterpayWidget: PropTypes.shape(itemPropTypes),
  buyFromBrandLogo: PropTypes.object.isRequired,
  buyFromBrandName: PropTypes.string,
  quantitySelector: PropTypes.bool,
  saveThis: PropTypes.bool,
  showProductUnitPrice: PropTypes.bool,
  defaultPaymentOption: PropTypes.string,
  headingColor: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  buttonHoverTextColor: PropTypes.string,
  buttonHoverBorderColor: PropTypes.string,
  buttonTextcolor: PropTypes.string,
  buyButtonBannerBackgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  afterpayWidget: null,
  buyFromBrandName: null,
  showProductUnitPrice: false,
  defaultPaymentOption: 'creditCard',
  headingColor: null,
  buttonColor: null,
  buttonTextColor: null,
  buttonBorderColor: null,
  buttonHoverColor: null,
  buttonHoverTextColor: null,
  buttonHoverBorderColor: null,
  buyButtonBannerBackgroundColor: null,
  headingFont: null,
  textFont: null,
};

function FunnelCart({
  products,
  afterpayWidget,
  buyFromBrandLogo,
  buyFromBrandName,
  quantitySelector,
  saveThis,
  showProductUnitPrice,
  defaultPaymentOption,
  headingColor,
  buttonColor,
  buttonTextColor,
  buttonBorderColor,
  buttonHoverColor,
  buttonHoverTextColor,
  buttonHoverBorderColor,
  buyButtonBannerBackgroundColor,
  headingFont,
  textFont,
}) {
  const { preview } = usePreviewData();
  const { offerData, offersConfig, isAfterpaySupported, brandExternalName } = useFunnelData();

  const brandName = buyFromBrandName ?? brandExternalName;

  const productsWithOffer = useMemo(() => {
    if (offerData.mock) console.warn('Using mock offer data');
    return products.map(({ item }, i) => {
      const config = offersConfig?.find(el => el.directusProductId === item.id);

      let offer;
      if (offerData.mock) {
        offer = offerData.data[i];
      } else {
        offer = offerData.data.find(el => el.id === config?.id);
      }

      return { ...item, offer, config };
    });
  }, [products, offerData, offersConfig]);

  // Offer/Directus Product mapping validation
  useEffect(() => {
    productsWithOffer.forEach(product => {
      if (!product.offer) missingPageOffer(product.id, preview);
    });
  }, [productsWithOffer, preview]);

  const [selectedProduct, setSelectedProduct] = useState(productsWithOffer[0]);

  const [selectedBuyFromType, setSelectedBuyFromType] = useState(BuyFromType.VELOCITY);

  const velocityBuyFrom = useMemo(
    () => selectedProduct.buyFroms.find(({ item }) => item.type === BuyFromType.VELOCITY)?.item,
    [selectedProduct]
  );

  const amazonBuyFrom = useMemo(
    () => selectedProduct.buyFroms.find(({ item }) => item.type === BuyFromType.AMAZON)?.item,
    [selectedProduct]
  );

  useEffect(() => {
    if (!amazonBuyFrom || amazonBuyFrom.availability !== BuyFromAvailability.AVAILABLE) {
      setSelectedBuyFromType(BuyFromType.VELOCITY);
    }
  }, [amazonBuyFrom]);

  useEffect(() => {
    productsWithOffer.forEach(({ offer, config }) => trackViewedProduct(offer, config));
  }, [productsWithOffer]);

  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const hasCalloutText = products.some(({ item }) => item.calloutText);
  const hasSavingsPercent = products.some(({ item }) => item.showSavingsPercent);

  return (
    <div className={`root ${styles.main}`}>
      <style jsx>{`
        .root {
          ${buildCssVar(
            '--buy-button-banner-background-color',
            buyButtonBannerBackgroundColor,
            'var(--default-background-color)'
          )}
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--button-color', buttonColor, 'var(--primary-color)')}
          ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-light)')}
          ${buildCssVar('--button-border-color', buttonBorderColor, 'var(--primary-color)')}
          ${buildCssVar('--button-hover-color', buttonHoverColor, 'var(--primary-color)')}
          ${buildCssVar(
            '--button-hover-text-color',
            buttonHoverTextColor,
            'var(--text-color-light)'
          )}
          ${buildCssVar(
            '--button-hover-border-color',
            buttonHoverBorderColor,
            'var(--primary-color)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <h2 className={styles.products_title}>Select Product</h2>
      <FunnelCartCarousel
        showButtons
        items={productsWithOffer.map(product => (
          <FunnelCartProduct
            key={product.id}
            onClick={setSelectedProduct}
            isSelected={product.id === selectedProduct.id}
            product={product}
            showProductUnitPrice={showProductUnitPrice}
            hasCalloutText={hasCalloutText}
            hasSavingsPercent={hasSavingsPercent}
          />
        ))}
      />
      {quantitySelector && <FunnelCartQuantitySelector product={selectedProduct} />}
      <h2 className={styles.products_title}>Buy From </h2>
      <div className={styles.buy_from_btn_wrapper}>
        <FunnelCartBuyFrom
          type={BuyFromType.VELOCITY}
          logo={buyFromBrandLogo}
          brandName={brandName}
          offer={selectedProduct.offer}
          buyFrom={velocityBuyFrom}
          isSelected={selectedBuyFromType === BuyFromType.VELOCITY}
          onClick={setSelectedBuyFromType}
        ></FunnelCartBuyFrom>
        <FunnelCartBuyFrom
          type={BuyFromType.AMAZON}
          offer={selectedProduct.offer}
          buyFrom={amazonBuyFrom}
          isSelected={selectedBuyFromType === BuyFromType.AMAZON}
          onClick={setSelectedBuyFromType}
        ></FunnelCartBuyFrom>
      </div>
      {isAfterpaySupported && afterpayWidget && (
        <AfterpayWidget
          price={selectedProduct.offer?.price}
          currency={selectedProduct.offer?.store.currency}
          {...afterpayWidget}
        />
      )}
      {saveThis && <FunnelCartSaveThis />}
      <StickyBuyButton
        buyFromType={selectedBuyFromType}
        offer={selectedProduct.offer}
        defaultPaymentOption={defaultPaymentOption}
      />
    </div>
  );
}

export default FunnelCart;

FunnelCart.propTypes = propTypes;
FunnelCart.defaultProps = defaultProps;

/* eslint-disable no-unused-vars */
import React, { SyntheticEvent } from 'react';
import Spinner from './Spinner';
import styles from './MultiVariantBundleItemDesktop.module.css';
import Image from './Image';
import formatCurrency from 'utils/format-currency';
import dynamic from 'next/dynamic';

const MarkdownText = dynamic(() => import('./MarkdownText'));

type Props = {
  shouldShowActivityIndicator: boolean;
  heading?: string;
  price?: number;
  compareAtPrice?: number;
  image?: string;
  previewImages?: string[];
  configName?: string;
  variantName?: string;
  offerName?: string;
  description?: string;
  offerId?: number;
  ctaText: string;
  getCheckoutClickHandler: (items: any[]) => (e: SyntheticEvent) => void;
  onVariantChange?: (e: SyntheticEvent) => void;
  selectedVariantName?: string;
  variantsWithOffer: any[];
  cssVarsText?: string;
  currencySymbol?: string;
};

export default function MultiVariantBundleItemDesktop({
  shouldShowActivityIndicator,
  heading,
  price,
  compareAtPrice,
  image,
  previewImages,
  configName,
  description,
  offerName,
  variantName,
  offerId,
  ctaText,
  onVariantChange,
  getCheckoutClickHandler,
  selectedVariantName,
  variantsWithOffer,
  cssVarsText,
  currencySymbol,
}: Props) {
  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{cssVarsText}</style>

      {shouldShowActivityIndicator && <Spinner />}
      {heading && (
        <div className={styles.bundle_banner}>
          <h3>{heading}</h3>
        </div>
      )}
      <div className={styles.details_container}>
        <div className={styles.product_detail_container}>
          <div className={styles.product_image_wrapper}>
            <Image className={styles.product_image} src={image} alt={configName} />
          </div>
          <div className={styles.bundle_name_wrapper}>
            <h2 className={styles.product_name}>{variantName || offerName}</h2>
          </div>
        </div>
        <div className={styles.badge_price_wrapper}>
          {/* TODO: Temp disabled */}
          {/* <div className={styles.badge_wrapper}>
            {previewImages?.map((preview: any, i) => (
              <Image className={styles.badge} src={preview?.item} alt={configName} key={i} />
            ))}
          </div> */}
          <div className={styles.prices_wrapper}>
            {compareAtPrice && (
              <p className={styles.compare_price}>
                {formatCurrency(compareAtPrice, false, currencySymbol)}
              </p>
            )}
            <h2 className={styles.price_wrapper}>{formatCurrency(price, false, currencySymbol)}</h2>
          </div>
        </div>

        <div className={styles.bundle_detail_wrapper}>
          <MarkdownText queryParams={null} text={description} />
        </div>

        <div className={styles.price_button_wrapper}>
          <select
            name="variants"
            id="variants"
            onChange={onVariantChange}
            className={styles.select}
            value={selectedVariantName}
          >
            {variantsWithOffer.map((item, i) => (
              <option value={item.variantName} key={i}>
                {item.variantName}
              </option>
            ))}
          </select>
          <button
            onClick={getCheckoutClickHandler([{ offerId, quantity: 1 }])}
            className={styles.product_btn}
          >
            {ctaText}
          </button>
        </div>
      </div>
    </div>
  );
}

import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styles from './Guarantee.theme1.module.css';
import Image from './Image';
import { useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on Guarantee': [
    { badge: ['...FilePartsWithMetadata'] },
    'text',
    'textColor',
    { textFont: ['family'] },
  ],
};

const propTypes = {
  badge: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  textColor: null,
  textFont: null,
};

export default function Guarantee({ badge, text, textColor, textFont }) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <div className={`root ${styles.guarantee_wrapper}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.badge_wrapper}>
        <Image alt="Guarantee" src={badge} placeholder="blur" />
      </div>
      <div className={styles.text_wrapper}>
        <MarkdownText text={text} />
      </div>
    </div>
  );
}

Guarantee.propTypes = propTypes;
Guarantee.defaultProps = defaultProps;

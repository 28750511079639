import PropTypes from 'prop-types';
import { buildCssVar } from 'utils/style-override';
import styles from './BlazeImageCarousel.module.css';
import useBlazeSlider from 'utils/useBlazeSlider';
import Image from './Image';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

const propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        image: PropTypes.object,
        mediaAlt: PropTypes.string,
        video: PropTypes.object,
        showVideoControls: PropTypes.bool,
        placeholderImage: PropTypes.object,
      }),
    })
  ).isRequired,
  showThumbnailsOnDesktop: PropTypes.bool,
  imageProgressDisplayOnMobile: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  buttonColor: PropTypes.string,
};

const defaultProps = {
  images: [],
  showThumbnailsOnDesktop: false,
  backgroundColor: null,
  buttonColor: null,
};

export default function BlazeImageCarousel({
  images,
  // showThumbnailsOnDesktop,
  // imageProgressDisplayOnMobile,
  backgroundColor,
  // buttonColor,
}) {
  const elRef = useBlazeSlider({
    all: {
      slidesToShow: 1,
      loop: true,
      enableAutoplay: true,
      enablePagination: true,
      autoplayInterval: 5000,
    },
  });

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
        }
      `}</style>
      <div className="blaze-slider" ref={elRef}>
        <div className="blaze-container">
          <div className="blaze-track-container">
            <div className="blaze-track">
              {images.map(({ item: { image, mediaAlt } }, i) => {
                return (
                  <div key={i}>
                    {image && (
                      <Image
                        alt={mediaAlt}
                        src={image}
                        priority={i === 0}
                        placeholder="blur"
                        sizes="670px"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.navControls}>
            <div className={`${styles.blazePrev} blaze-prev`} aria-label="Go to previous slide">
              <FaChevronLeft />
            </div>
            <div className={`${styles.blazePagination} blaze-pagination`}></div>
            <div className={`${styles.blazeNext} blaze-next`} aria-label="Go to next slide">
              <FaChevronRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BlazeImageCarousel.propTypes = propTypes;
BlazeImageCarousel.defaultProps = defaultProps;

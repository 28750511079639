import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Image from './Image';
import DetailListModal from './DetailListModal';
import styles from './DetailListItem.module.css';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on DetailListItem': [
    { image: ['...FilePartsWithMetadata'] },
    'imageAlt',
    'title',
    'subtitle',
    'text',
    'modalLinkText',
    'modalTitle',
    'modalText',
    'headingColor',
    'textColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

export const itemPropTypes = {
  image: PropTypes.object.isRequired,
  imageAlt: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  modalLinkText: PropTypes.string,
  modalTitle: PropTypes.string,
  modalText: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const propTypes = {
  index: PropTypes.number.isRequired,
  ...itemPropTypes,
};

const defaultProps = {
  title: null,
  subtitle: null,
  text: undefined,
  modalLinkText: null,
  modalTitle: null,
  modalText: undefined,
  headingColor: null,
  textColor: null,
  headingFont: null,
  textFont: null,
};

export default function DetailListItem({
  index,
  image,
  imageAlt,
  title,
  subtitle,
  text,
  modalLinkText,
  modalTitle,
  modalText,
  headingColor,
  textColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  const directionClass = index % 2 === 0 ? styles.image_left : styles.image_right;
  return (
    <div className={`root ${styles.container} ${directionClass}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.image_container}>
        <div className={styles.image_wrapper}>
          <Image src={image} alt={imageAlt} placeholder="blur" />
        </div>
      </div>
      <div className={styles.detail}>
        {title && <h2 className={styles.heading}>{title}</h2>}
        {subtitle && <h3 className={styles.subheading}>{subtitle}</h3>}
        {text && (
          <div className={styles.text}>
            <MarkdownText text={text} />
          </div>
        )}
        {modalLinkText && (
          <DetailListModal
            linkText={modalLinkText}
            title={modalTitle}
            text={modalText}
            headingColor={headingColor}
            textColor={textColor}
            headingFont={headingFont}
            textFont={textFont}
          />
        )}
      </div>
    </div>
  );
}

DetailListItem.propTypes = propTypes;
DetailListItem.defaultProps = defaultProps;

import PropTypes from 'prop-types';
import styles from './SimpleTestimonial.theme1.module.css';
import SimpleReview, { queryTemplate as simpleReviewQueryTemplate } from './SimpleReview.theme1';
import { reviewPropTypes } from 'utils/prop-types';
import { buildCssVar } from 'utils/style-override';

export const queryTemplate = {
  '... on SimpleTestimonial': [
    'dividerText',
    {
      reviews: [
        {
          item: [simpleReviewQueryTemplate],
        },
      ],
    },
    'backgroundColor',
  ],
};

const propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({ item: PropTypes.shape(reviewPropTypes) }))
    .isRequired,
  backgroundColor: PropTypes.string,
};

const defaultProps = {
  backgroundColor: null,
};

export default function SimpleTestimonial({ reviews, backgroundColor }) {
  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
        }
      `}</style>

      <div className={styles.reviews_wrapper}>
        {reviews.map(({ item }, i) => (
          <SimpleReview key={i} {...item} />
        ))}
      </div>
    </div>
  );
}

SimpleTestimonial.propTypes = propTypes;
SimpleTestimonial.defaultProps = defaultProps;

import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Image from './Image';
import styles from './ValuePropositionItem.theme1.module.css';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';
import LazyAutoplayVideo from './LazyAutoplayVideo';
import { queryTemplate as bulletQueryTemplate } from './Bullet';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on ValuePropositionItem': [
    'caption',
    'title',
    'text',
    {
      bullets: [
        {
          item: [bulletQueryTemplate],
        },
      ],
    },
    { image: ['...FilePartsWithMetadata'] },
    { placeholderImage: ['...FilePartsWithMetadata'] },
    { video: ['...FileParts'] },
    'mediaAlt',
    'captionColor',
    'captionBackgroundColor',
    'headingColor',
    'headingBackgroundColor',
    'textColor',
    'backgroundColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  placeholderImage: PropTypes.object,
  image: PropTypes.object,
  video: PropTypes.object,
  mediaAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  placeholderImage: null,
  image: null,
  video: null,
  mediaAlt: null,
  text: undefined,
  headingColor: null,
  textColor: null,
  backgroundColor: null,
  headingFont: null,
  textFont: null,
};

export default function ValuePropositionItem({
  image,
  placeholderImage,
  video,
  mediaAlt,
  title,
  text,
  headingColor,
  textColor,
  backgroundColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-light)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--primary-color)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {video && (
        <LazyAutoplayVideo alt={mediaAlt} placeholderImage={placeholderImage} video={video} />
      )}
      {image && <Image src={image} alt={mediaAlt} placeholder="blur" />}
      <div className={styles.value_text}>
        <h4>{title}</h4>
        {text && <MarkdownText text={text} />}
      </div>
    </div>
  );
}

ValuePropositionItem.propTypes = propTypes;
ValuePropositionItem.defaultProps = defaultProps;

import { customAlphabet } from 'nanoid';
import { useEffect, useState } from 'react';
import fetcher from './fetcher';
import { parsePrice } from './price';
import { buildImageUrl, isSecure } from './url';
import { reportError } from './report-error';

const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 10);

export const identify = customerData => {
  if (!isSecure()) return;
  if (!window._learnq) return;
  if (!customerData.email && !customerData.phone) {
    throw new Error('Identify requires email or phone');
  }

  const data = {};
  if (customerData.email) data.$email = customerData.email;
  if (customerData.firstName) data.$first_name = customerData.firstName;
  if (customerData.lastName) data.$last_name = customerData.lastName;
  if (customerData.shippingAddress) data.$address1 = customerData.shippingAddress;
  if (customerData.shippingCity) data.$city = customerData.shippingCity;
  if (customerData.shippingState) data.$region = customerData.shippingState;
  if (customerData.shippingCountry) data.$country = customerData.shippingCountry;
  if (customerData.shippingZip) data.$zip = customerData.shippingZip;
  if (customerData.phone) data.$phone_number = customerData.phone;

  return window._learnq.push(['identify', data]);
};

// Get Klaviyo identity cookie data
//
// Using global variables (i.e. _learnq) as hook dependencies doesn't work because
// changing them does not trigger a render. For that reason, we want this hook to run
// every render as _learnq loads from an async script and will not be defined immediately.
export const useIdentity = () => {
  const [identity, setIdentity] = useState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!identity) setIdentity(window?._learnq?.identify?.());
  });
  return identity;
};

export const track = (eventName, eventData) => {
  if (!isSecure()) return;
  if (!window._learnq) return;

  return window._learnq.push(['track', eventName, eventData]);
};

// Tracks recently viewed items under a person's profile under a "Recently Viewed Items" section
// https://help.klaviyo.com/hc/en-us/articles/115005082927-Guide-to-Integrating-a-Standard-Ecommerce-Platform#viewed-product3
export const trackViewedItem = eventData => {
  if (!isSecure()) return;
  if (!window._learnq) return;

  return window._learnq.push(['trackViewedItem', eventData]);
};

export const trackViewedProduct = (offer, offerConfig) => {
  track('Viewed Product', {
    ProductName: offer?.name,
    ProductID: offer?.id,
    ImageURL: buildImageUrl(offerConfig?.image),
    URL: offerConfig?.productUrl,
    Brand: offer?.store?.brand?.name,
    Price: parsePrice(offer?.price),
  });

  trackViewedItem({
    Title: offer?.name,
    ItemId: offer?.id,
    ImageUrl: buildImageUrl(offerConfig?.image),
    Url: offerConfig?.productUrl,
    Metadata: {
      Price: parsePrice(offer?.price),
      Brand: offer?.store?.brand?.name,
    },
  });
};

export const useTrackViewedProduct = (offer, offerConfig) => {
  useEffect(() => trackViewedProduct(offer, offerConfig), [offer, offerConfig]);
};

const sendServerSideTrack = async (event, customerProperties, properties, klaviyoPublicKey) => {
  if (!klaviyoPublicKey) return;
  try {
    const data = {
      token: klaviyoPublicKey,
      event,
    };
    if (customerProperties) data.customer_properties = customerProperties;
    if (properties) data.properties = properties;

    const result = await fetcher('https://a.klaviyo.com/api/track', {
      method: 'POST',
      headers: {
        Accept: 'text/html',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        data: JSON.stringify(data),
      }),
    });

    return result;
  } catch (err) {
    console.error(err.message);
    reportError(err);
  }
};

// This event uses the Klaviyo server-side api instead of the JS snippet API to avoid
// the requirement that the user has interacted after identification.
// https://help.klaviyo.com/hc/en-us/articles/115005082927-Guide-to-Integrating-a-Standard-Ecommerce-Platform#started-checkout5
export const trackStartedCheckout = (
  { email, phone },
  cartData,
  offersConfig,
  funnelId,
  klaviyoPublicKey
) => {
  const brandNames = new Set();
  const itemNames = [];
  const items = [];

  cartData?.data.lineItems.forEach(lineItem => {
    const config = offersConfig.find(el => el.id === lineItem.offerId);

    brandNames.add(lineItem.brandName);
    itemNames.push(lineItem.name);

    items.push({
      ProductID: lineItem.offerId,
      ProductName: lineItem.name,
      Quantity: lineItem.quantity,
      ItemPrice: lineItem.originalPrice,
      RowTotal: (parsePrice(lineItem.originalPrice) * lineItem.quantity).toFixed(2),
      ProductURL: config?.productUrl,
      ImageURL: buildImageUrl(config?.image),
      Brand: lineItem.brandName,
    });
  });

  const customerProperties = {};
  if (email) {
    customerProperties.$email = email;
  } else if (phone) {
    customerProperties.$phone_number = phone;
  }

  return sendServerSideTrack(
    'Started Checkout',
    customerProperties,
    {
      // $event_id should be a unique identifier for the cart combined with the UNIX formatted time
      // when the event was triggered
      $event_id: `${nanoid()}_${Math.floor(Date.now() / 1000)}`,
      $value: parsePrice(cartData?.data.total),
      ItemNames: itemNames,
      Brands: [...brandNames],
      CheckoutURL: window.location.href,
      Items: items,
    },
    klaviyoPublicKey
  );
};

export const trackSavedProduct = ({ email, phone }, offerData, offersConfig, klaviyoPublicKey) => {
  const customerProperties = {};

  if (email) {
    customerProperties.$email = email;
    customerProperties.$consent = ['email'];
  } else if (phone) {
    customerProperties.$phone_number = phone;
  }

  return sendServerSideTrack(
    'Saved Product',
    customerProperties,
    getOffersProperties(offerData, offersConfig),
    klaviyoPublicKey
  );
};

export const trackSmsConciergeRequest = (phone, offerData, offersConfig, klaviyoPublicKey) => {
  return sendServerSideTrack(
    'SMS Concierge Request',
    { $phone_number: phone },
    getOffersProperties(offerData, offersConfig),
    klaviyoPublicKey
  );
};

const getOffersProperties = (offerData, offersConfig) => {
  const items = [];
  const itemNames = [];
  // Set lets you store unique values of any type
  // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
  const brandNames = new Set();
  for (const offer of offerData.data) {
    const price = parsePrice(offer.price);
    const itemName = offer.name;
    if (itemName) {
      itemNames.push(itemName);
    }

    const brandName = offer.store?.brand?.name;

    if (brandName) {
      brandNames.add(brandName);
    }

    const offerConfig = offersConfig?.find(el => el.id === offer?.id);

    items.push({
      ProductID: offer?.id,
      ProductName: offer?.name,
      Quantity: 1,
      ItemPrice: price,
      RowTotal: price,
      ProductURL: offerConfig?.productUrl,
      ImageURL: buildImageUrl(offerConfig?.image),
      Brand: brandName,
    });
  }

  return {
    // $event_id should be a unique identifier for the cart combined with the UNIX formatted time
    // when the event was triggered
    $event_id: `${nanoid()}_${Math.floor(Date.now() / 1000)}`,
    ItemNames: itemNames,
    Brands: [...brandNames],
    ProductURL: offersConfig[0]?.productUrl,
    Items: items,
  };
};

import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useHeadingFontOverrides, useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on HeadingText': [
    'heading',
    'text',
    'headingColor',
    'textColor',
    'backgroundColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  headingFontFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
  styles: PropTypes.object,
};

const defaultProps = {
  heading: null,
  text: null,
  headingColor: null,
  textColor: null,
  backgroundColor: null,
  headingFont: null,
  textFont: null,
  styles: null,
};

export default function HeadingText({
  heading,
  text,
  formattedText,
  headingColor,
  textColor,
  backgroundColor,
  headingFont,
  textFont,
  styles,
}) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${textFontOverrides ?? ''}
          ${headingFontOverrides ?? ''}
        }
      `}</style>

      {heading && <h1 className={styles.heading}>{heading}</h1>}

      {text && (
        <div className={styles.text}>
          {!formattedText && <MarkdownText text={text}></MarkdownText>}
          {formattedText && <div dangerouslySetInnerHTML={{ __html: formattedText }} />}
        </div>
      )}
    </div>
  );
}

HeadingText.propTypes = propTypes;
HeadingText.defaultProps = defaultProps;

import PropTypes from 'prop-types';
import RatingStars from './RatingStars';
import styles from './TestimonialRating.theme1.module.css';
import { useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

export const queryTemplate = {
  '... on TestimonialRating': [
    'text',
    'rating',
    'textColor',
    'starColor',
    'starEmptyColor',
    { headingFont: ['family'] },
  ],
};

const propTypes = {
  text: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  textColor: PropTypes.string,
  starColor: PropTypes.string,
  starEmptyColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  textColor: null,
  starColor: null,
  starEmptyColor: null,
  headingFont: null,
};

export default function TestimonialRating({
  text,
  rating,
  textColor,
  starColor,
  starEmptyColor,
  headingFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  return (
    <div className={`root ${styles.item}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
        }
        ${headingFontOverrides ?? ''}
      `}</style>

      <h4>{text}</h4>
      <div className={styles.star}>
        <h4>{rating?.toFixed(1)}</h4>
        <div className={styles.star_wrapper}>
          <RatingStars rating={rating} starColor={starColor} starEmptyColor={starEmptyColor} />
        </div>
      </div>
    </div>
  );
}

TestimonialRating.propTypes = propTypes;
TestimonialRating.defaultProps = defaultProps;

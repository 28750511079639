import bannerQueryTemplate from 'generic/query-templates/bannerQueryTemplate';
import { queryTemplate as countdownBannerQueryTemplate } from '../generic/CountdownBanner';
import { queryTemplate as multiVariantBundleQueryTemplate } from '../generic/MultiVariantBundle';
import { queryTemplate as miniCartQueryTemplate } from '../generic/MiniCart';
import { queryTemplate as videoQueryTemplate } from '../generic/Video';
import { queryTemplate as multiBundleQueryTemplate } from '../generic/MultiBundle';
import { queryTemplate as accordionQueryTemplate } from '../generic/Accordion';
import { queryTemplate as autoplayVideoQueryTemplate } from '../generic/AutoplayVideo';
import { queryTemplate as comparisonQueryTemplate } from '../generic/Comparison';
import { queryTemplate as featuredInQueryTemplate } from '../generic/FeaturedIn';
import { queryTemplate as guaranteeQueryTemplate } from '../generic/Guarantee.theme1';
import { queryTemplate as heroQueryTemplate } from '../generic/Hero';
import { queryTemplate as multiProductQueryTemplate } from '../generic/MultiProduct';
import { queryTemplate as storyQueryTemplate } from '../generic/Story';
import { queryTemplate as valuePropositionQueryTemplate } from '../generic/ValueProposition.theme1';
import { queryTemplate as detailListQueryTemplate } from '../generic/DetailList';
import { queryTemplate as simpleTestimonialQueryTemplate } from '../generic/SimpleTestimonial.theme1';
import { queryTemplate as testimonialQueryTemplate } from '../generic/Testimonial.theme1';
import { queryTemplate as twoColumnQueryTemplate } from '../generic/TwoColumn';
import { queryTemplate as trustBoxesQueryTemplate } from '../generic/TrustBoxes';
import { queryTemplate as brandInformationModalQueryTemplate } from '../generic/BrandInformationModal';

const interactQuizQueryTemplate = {
  '... on InteractQuiz': ['quizId', 'disableCoverPage'],
};
const stickyButtonAnchorQueryTemplate = {
  '... on StickyButtonAnchor': ['id'],
};
const showStickyButtonQueryTemplate = {
  '... on ShowStickyButton': ['id'],
};
const bylineQueryTemplate = {
  '... on Byline': [
    { image: ['...FilePartsWithMetadata'] },
    'imageAlt',
    'text',
    'textColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};
const headingImageTextQueryTemplate = {
  '... on HeadingImageText': [
    'heading',
    { image: ['...FilePartsWithMetadata'] },
    'imageAlt',
    'text',
    'headingColor',
    'textColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const imageOnlyQueryTemplate = {
  '... on ImageOnly': [
    {
      images: [
        {
          item: [
            {
              '... on FeaturedInImage': [{ image: ['...FilePartsWithMetadata'] }, 'imageAlt'],
            },
          ],
        },
      ],
    },
  ],
};
const urgencyQueryTemplate = {
  urgencyTimer: [
    'heading',
    'text',
    'duration',
    { icon: ['...FilePartsWithMetadata'] },
    'headingColor',
    'textColor',
    'backgroundColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const checkoutFormQueryTemplate = {
  checkoutForm: [
    'buttonColor',
    'buttonTextColor',
    'buttonHoverColor',
    'buttonHoverTextColor',
    'buttonBorderColor',
    'buttonHoverBorderColor',
    'backgroundColor',
  ],
};

const upsellMiniCartQueryTemplate = {
  '... on UpsellMiniCart': [
    'shippingText',
    'acceptButtonText',
    'rejectButtonText',
    'backgroundColor',
    'textColor',
    'acceptButtonTextColor',
    'acceptButtonColor',
    'acceptButtonBorderColor',
    'acceptButtonHoverBorderColor',
    'acceptButtonHoverColor',
    'acceptButtonHoverTextColor',
    'rejectButtonTextColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};
const ctaButtonQueryTemplate = {
  '... on CtaButton': [
    'buttonText',
    'buttonColor',
    'buttonHoverColor',
    'buttonTextColor',
    'buttonHoverTextColor',
    'buttonBorderColor',
    'buttonHoverBorderColor',
    { textFont: ['family'] },
  ],
};
const stickyButtonQueryTemplate = {
  stickyButton: [
    'buttonText',
    'text',
    'buttonColor',
    'buttonHoverColor',
    'buttonTextColor',
    'buttonHoverTextColor',
    'buttonBorderColor',
    'buttonHoverBorderColor',
    'textColor',
    { textFont: ['family'] },
  ],
};

const logosBannerQueryTemplate = {
  '... on LogosBanner': [
    'overlayText',
    {
      images: [
        {
          item: [
            {
              '... on LogosBannerImage': [{ image: ['...FilePartsWithMetadata'] }, 'imageAlt'],
            },
          ],
        },
      ],
    },
    'starColor',
    'backgroundColor',
    'textColor',
    { textFont: ['family'] },
  ],
};

const orderSummaryQueryTemplate = {
  orderSummary: ['discountCodeTextColor', 'discountCodeButtonColor'],
};
const photoScrollerBannerQueryTemplate = {
  '... on PhotoScrollerBanner': [
    'overlayText',
    {
      images: [
        {
          item: [
            {
              '... on PhotoScrollerBannerImage': [
                {
                  image: ['...FilePartsWithMetadata'],
                },
                'imageAlt',
              ],
            },
          ],
        },
      ],
    },
    'backgroundColor',
    'textColor',
    { textFont: ['family'] },
  ],
};

const stickyBannerQueryTemplate = {
  '... on StickyBanner': [
    'sectionId',
    'buttonText',
    'text',
    {
      image: ['...FilePartsWithMetadata'],
    },
    'imageAlt',
    'buttonColor',
    'buttonHoverColor',
    'buttonTextColor',
    'buttonHoverTextColor',
    'buttonBorderColor',
    'buttonHoverBorderColor',
    'textColor',
    'backgroundColor',
    {
      textFont: ['family'],
    },
  ],
};

const mediaTextBlockQueryTemplate = {
  '... on MediaTextBlock': [
    'sectionId',
    'title',
    'heading',
    'text',
    {
      reviews: [
        {
          item: [
            {
              '... on ReviewBlock': ['id', 'heading', 'text', 'rating', 'reviewerName'],
            },
          ],
        },
      ],
    },
    { image: ['...FilePartsWithMetadata'] },
    { video: ['...FileParts'] },
    { placeholderImage: ['...FilePartsWithMetadata'] },
    'mediaAlt',
    'titleColor',
    'headingColor',
    'textColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const finalCtaQueryTemplate = {
  '... on FinalCta': [
    'title',
    'subtitle',
    {
      image: ['...FilePartsWithMetadata'],
    },
    'text',
    'url',
    'buttonText',
    'guaranteeText',
    'backgroundColor',
    'textColor',
    'titleColor',
    'subtitleColor',
    'buttonColor',
    'buttonTextColor',
    'buttonOnHoverColor',
    'buttonBorderColor',
    'buttonOnHoverTextColor',
    'buttonOnHoverBorderColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const headerLogoQueryTemplate = {
  '... on HeaderLogo': [
    {
      image: ['...FilePartsWithMetadata'],
    },
    {
      secondImage: ['...FilePartsWithMetadata'],
    },
    'backgroundColor',
  ],
};

const navBarQueryTemplate = {
  '... on NavBar': [
    'hideOnMobile',
    {
      logo: ['...FilePartsWithMetadata'],
    },
    {
      links: [
        {
          item: [
            {
              '... on NavBarLink': ['sectionId', 'text'],
            },
          ],
        },
      ],
    },
    'linkTextColor',
    {
      textFont: ['family'],
    },
  ],
};

export {
  bannerQueryTemplate,
  headerLogoQueryTemplate,
  countdownBannerQueryTemplate,
  navBarQueryTemplate,
  stickyBannerQueryTemplate,
  logosBannerQueryTemplate,
  interactQuizQueryTemplate,
  stickyButtonAnchorQueryTemplate,
  showStickyButtonQueryTemplate,
  multiVariantBundleQueryTemplate,
  miniCartQueryTemplate,
  videoQueryTemplate,
  multiBundleQueryTemplate,
  accordionQueryTemplate,
  autoplayVideoQueryTemplate,
  comparisonQueryTemplate,
  featuredInQueryTemplate,
  guaranteeQueryTemplate,
  heroQueryTemplate,
  multiProductQueryTemplate,
  storyQueryTemplate,
  testimonialQueryTemplate,
  valuePropositionQueryTemplate,
  detailListQueryTemplate,
  bylineQueryTemplate,
  headingImageTextQueryTemplate,
  imageOnlyQueryTemplate,
  twoColumnQueryTemplate,
  stickyButtonQueryTemplate,
  upsellMiniCartQueryTemplate,
  ctaButtonQueryTemplate,
  urgencyQueryTemplate,
  checkoutFormQueryTemplate,
  simpleTestimonialQueryTemplate,
  orderSummaryQueryTemplate,
  trustBoxesQueryTemplate,
  photoScrollerBannerQueryTemplate,
  brandInformationModalQueryTemplate,
  mediaTextBlockQueryTemplate,
  finalCtaQueryTemplate,
};

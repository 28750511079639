import styles from './Hero.module.css';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import RatingStars from './RatingStars';
import Image from './Image';

import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on Hero': [
    { image: ['...FilePartsWithMetadata'] },
    'imageAlt',
    'rating',
    'ratingText',
    'heading',
    'subheading',
    'text',
    {
      badges: [
        {
          item: [
            {
              '... on HeroBadge': [{ image: ['...FilePartsWithMetadata'] }, 'imageAlt'],
            },
          ],
        },
      ],
    },
    'headingColor',
    'subheadingColor',
    'textColor',
    'ratingTextColor',
    'starColor',
    'starEmptyColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  image: PropTypes.object.isRequired,
  imageAlt: PropTypes.string.isRequired,
  ratingText: PropTypes.string,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  text: PropTypes.string.isRequired,
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        image: PropTypes.object.isRequired,
        imageAlt: PropTypes.string.isRequired,
      }),
    })
  ),
  rating: PropTypes.number,
  headingColor: PropTypes.string,
  subheadingColor: PropTypes.string,
  textColor: PropTypes.string,
  ratingTextColor: PropTypes.string,
  starColor: PropTypes.string,
  starEmptyColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  subheading: null,
  ratingText: null,
  badges: [],
  rating: 0,
  headingColor: null,
  subheadingColor: null,
  textColor: null,
  ratingTextColor: null,
  starColor: null,
  starEmptyColor: null,
  headingFont: null,
  textFont: null,
};

export default function Hero({
  image,
  imageAlt,
  ratingText,
  heading,
  subheading,
  text,
  badges,
  rating,
  headingColor,
  subheadingColor,
  textColor,
  ratingTextColor,
  starColor,
  starEmptyColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <div className={`root ${styles.hero_container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--subheading-color', subheadingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--rating-text-color', ratingTextColor, 'var(--text-color-dark)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.img_wrapper}>
        <Image src={image} alt={imageAlt} priority placeholder="blur" />
      </div>
      <div className={styles.hero_content}>
        <div className={styles.hero_reviews}>
          {!!rating && (
            <RatingStars
              className={styles.stars}
              rating={rating}
              starColor={starColor}
              starEmptyColor={starEmptyColor}
            />
          )}
          {ratingText && (
            <div className={styles.rating_text}>
              <MarkdownText text={ratingText} />
            </div>
          )}
        </div>
        <h1>{heading}</h1>
        {subheading && <h2>{subheading}</h2>}
        <MarkdownText text={text} />
        {badges.length !== 0 && (
          <div className={styles.hero_logo_container}>
            {badges.map(({ item }, i) => (
              <div key={i}>
                <Image src={item.image} alt={item.imageAlt} priority placeholder="blur" />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import { getPercentSaved, parsePrice } from 'utils/price';
import styles from './FunnelCartBuyFrom.module.css';
import amazon from 'public/amazon.png';
import amazonPrime from 'public/amazon-prime.png';
import { useCart } from 'utils/cart-context';
import formatCurrency from 'utils/format-currency';
import { BuyFromType, BuyFromAvailability } from 'constant';
import { onSelectBuyFrom } from 'utils/gtm';
import * as rudder from 'utils/rudderstack';

export const queryTemplate = {
  '... on ProductBuyFrom': [
    'id',
    'type',
    'deliveryText',
    'returnsText',
    'amazonPrime',
    'availability',
    'tags',
  ],
};

export const buyFromPropTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([BuyFromType.VELOCITY, BuyFromType.AMAZON]).isRequired,
  deliveryText: PropTypes.string,
  returnsText: PropTypes.string,
  amazonPrime: PropTypes.bool,
  availability: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
};

const propTypes = {
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  offer: PropTypes.object,
  buyFrom: PropTypes.shape(buyFromPropTypes),
  logo: PropTypes.object,
  brandName: PropTypes.string,
};

const defaultProps = {
  offer: null,
  buyFrom: null,
  logo: null,
  brandName: null,
};

function FunnelCartBuyFrom({ onClick, isSelected, offer, type, logo, brandName, buyFrom }) {
  const classNames = ['root', styles.main, isSelected ? styles.selected : styles.unselected];

  const { cartData, cartIsReady } = useCart();

  // TODO: Move offer data quantity handling into context so it can be shared between
  // components. With that change, we would no longer need to use cart data in this component.
  const cartLineItem = useMemo(
    () => cartData?.data?.lineItems?.find(item => item.offerId === offer?.id),
    [cartData, offer?.id]
  );

  const quantityIsReady = cartIsReady && cartLineItem;

  const handleClick = () => {
    onClick(type);
    onSelectBuyFrom({ ...offer, quantity: quantityIsReady ? cartLineItem.quantity : 1 }, type);

    rudder.onSelectBuyFrom(
      { ...offer, quantity: quantityIsReady ? cartLineItem.quantity : 1 },
      type
    );
  };

  const percentageOff = useMemo(() => {
    if (!offer?.compareAtPrice || !offer?.price) {
      return null;
    }

    const percentSaved = getPercentSaved(offer.compareAtPrice, offer.price);
    return percentSaved.toFixed(0);
  }, [offer?.compareAtPrice, offer?.price]);

  const availability = useMemo(() => {
    if (type === BuyFromType.VELOCITY) {
      return BuyFromAvailability.AVAILABLE;
    }

    if (type === BuyFromType.AMAZON) {
      // If Buy From availability is set to available check Amazon Price and Amazon Link is also set
      if (buyFrom?.availability === BuyFromAvailability.AVAILABLE) {
        if (offer?.amazonPrice && offer?.amazonLink) {
          return BuyFromAvailability.AVAILABLE;
        }
        // Otherwise only use availability set on Buy From if it is defined and not set to Available
      } else if (buyFrom?.availability) {
        return buyFrom.availability;
      }
    }

    // Always assume not available as default
    return BuyFromAvailability.NOT_AVAILABLE;
  }, [type, offer, buyFrom]);

  return (
    <button
      className={classNames.join(' ')}
      onClick={handleClick}
      disabled={availability !== BuyFromAvailability.AVAILABLE}
    >
      <div className={styles.text_wrapper}>
        {type === BuyFromType.VELOCITY && (
          <div className={styles.logo_name}>
            <Image src={logo} alt={brandName} width={38} height={38} />
            <h5>{brandName}</h5>
          </div>
        )}
        {type === BuyFromType.AMAZON && (
          <div className={styles.logo_name}>
            <Image src={amazon} alt="Amazon" width={38} height={38} />
            <h5>Amazon.com</h5>
          </div>
        )}

        {availability === BuyFromAvailability.SOLD_OUT && <div>This product is sold out</div>}
        {availability === BuyFromAvailability.NOT_AVAILABLE && (
          <div>This product is not available</div>
        )}

        {availability === BuyFromAvailability.AVAILABLE && (
          <>
            {buyFrom && (
              <>
                {buyFrom.deliveryText && (
                  <span>
                    Free delivery: {buyFrom.deliveryText}
                    {type === BuyFromType.AMAZON && buyFrom.amazonPrime && (
                      <Image src={amazonPrime} alt="Amazon Prime" width={54} height={22} />
                    )}
                  </span>
                )}
                {buyFrom.returnsText && <div>Free returns: {buyFrom.returnsText}</div>}
                {buyFrom.tags && (
                  <div className={styles.tags_wrapper}>
                    {buyFrom.tags.map(tag => (
                      <span key={tag}>{tag}</span>
                    ))}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      {availability === BuyFromAvailability.AVAILABLE && (
        <div className={styles.price_wrapper}>
          {type === BuyFromType.AMAZON && (
            <p className={styles.price}>
              {!quantityIsReady && 'Calculating...'}
              {quantityIsReady &&
                formatCurrency(parsePrice(offer?.amazonPrice) * cartLineItem.quantity)}
            </p>
          )}
          {type === BuyFromType.VELOCITY && (
            <div className={styles.price_wrapper}>
              {!quantityIsReady && <p className={styles.price}>Calculating...</p>}
              {quantityIsReady && (
                <>
                  {percentageOff && <p className={styles.percentage_off}>-{percentageOff}%</p>}
                  <div>
                    <p className={styles.price}>{formatCurrency(cartData.data.subtotal)}</p>
                    {offer?.compareAtPrice && (
                      <p className={styles.compareAtPrice}>
                        {formatCurrency(parsePrice(offer?.compareAtPrice) * cartLineItem.quantity)}
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </button>
  );
}

export default FunnelCartBuyFrom;

FunnelCartBuyFrom.propTypes = propTypes;
FunnelCartBuyFrom.defaultProps = defaultProps;

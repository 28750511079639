import PropTypes from 'prop-types';
import { useMemo, useEffect, useState, useCallback } from 'react';
import styles from './QuantitySelector.module.css';
import Callout, { queryTemplate as calloutQueryTemplate } from './Callout';
import { FaShippingFast } from 'react-icons/fa';
import { buildCssVar } from 'utils/style-override';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import QuantitySelectorProduct, {
  queryTemplate as productQueryTemplate,
  productPropTypes,
} from './QuantitySelectorProduct';
import Spinner from './Spinner';
import { trackViewedProduct } from 'utils/klaviyo';
import { useFunnelData } from 'utils/funnel-data-context';
import { missingPageOffer } from 'utils/error';
import { usePreviewData } from 'utils/preview-data-context';
import { getPercentSaved, parsePrice } from 'utils/price';
import { useOnCheckoutClick } from 'utils/checkout-link';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { addQueryParamsToUrl } from 'utils/article-link';

export const queryTemplate = {
  '... on QuantitySelector': [
    {
      products: [
        {
          item: [productQueryTemplate],
        },
      ],
    },
    { callout: [...Object.values(calloutQueryTemplate)[0]] },
    'ctaText',
    'externalLink',
    'hideQuantitySelector',
    'textColor',
    'headingColor',
    'buttonTextColor',
    'buttonBorderColor',
    'buttonHoverColor',
    'buttonBackgroundColor',
    'buttonHoverTextColor',
    'buttonHoverBorderColor',
    'compareAtColor',
    { textFont: ['family'] },
    { headingFont: ['family'] },
  ],
};

const propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape(productPropTypes),
    })
  ),
  callout: PropTypes.shape(Callout.propTypes),
  ctaText: PropTypes.string,
  externalLink: PropTypes.string,
  hideQuantitySelector: PropTypes.bool,
  textColor: PropTypes.string,
  headingColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonHoverTextColor: PropTypes.string,
  buttonHoverBorderColor: PropTypes.string,
  compareAtColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
  headingFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  callout: null,
  textColor: null,
  externalLink: null,
  hideQuantitySelector: null,
  headingColor: null,
  buttonTextColor: null,
  buttonBorderColor: null,
  buttonHoverColor: null,
  buttonBackgroundColor: null,
  buttonHoverTextColor: null,
  buttonHoverBorderColor: null,
  compareAtColor: null,
  textFont: null,
  headingFont: null,
};

function QuantitySelector({
  products,
  ctaText,
  callout,
  textColor,
  externalLink,
  hideQuantitySelector,
  headingColor,
  buttonTextColor,
  buttonBorderColor,
  buttonHoverColor,
  buttonBackgroundColor,
  buttonHoverTextColor,
  buttonHoverBorderColor,
  compareAtColor,
  textFont,
  headingFont,
}) {
  //TODO: Please remove this hook once the support for multiple offer is added.
  useEffect(() => {
    if (products.length > 1) {
      throw new Error(
        'Quantity Selector component can only support one offer! Please keep just one product for this collection in Directus'
      );
    }
  }, [products.length]);

  const router = useRouter();

  const { offersConfig, offerData } = useFunnelData();
  const { preview } = usePreviewData();

  const productsWithOffer = useMemo(() => {
    if (offerData.mock) console.warn('Using mock offer data');
    return products.map(({ item }, i) => {
      const config = offersConfig?.find(el => el.directusProductId === item.id);

      let offer;
      if (offerData.mock) {
        offer = offerData.data[i];
      } else {
        offer = offerData.data.find(el => el.id === config?.id);
      }

      return { ...item, offer, config };
    });
  }, [products, offerData, offersConfig]);

  // Offer/Directus Product mapping validation
  useEffect(() => {
    productsWithOffer.forEach(product => {
      if (!product?.offer) missingPageOffer(product.id, preview);
    });
  }, [productsWithOffer, preview]);

  useEffect(() => {
    productsWithOffer.forEach(({ offer, config }) => trackViewedProduct(offer, config));
  }, [productsWithOffer]);

  const price = parsePrice(productsWithOffer[0]?.offer?.price);
  const compareAtPrice = parsePrice(productsWithOffer[0]?.offer?.compareAtPrice);

  const savedPercent = useMemo(() => {
    return getPercentSaved(compareAtPrice, price);
  }, [compareAtPrice, price]);

  const initialCartItems = useMemo(
    () =>
      productsWithOffer.map(({ offer }, i) => ({ offerId: offer?.id, quantity: i === 0 ? 1 : 0 })),
    [productsWithOffer]
  );

  const [cartItems, setCartItems] = useState(initialCartItems);

  const onQuantityChange = useCallback(
    (offerId, quantity) => {
      setCartItems(
        cartItems.map(item => {
          if (item.offerId === offerId) {
            return { ...item, quantity };
          }

          return item;
        })
      );
    },
    [cartItems]
  );

  const totalQuantity = useMemo(
    () => cartItems.reduce((total, { quantity }) => total + quantity, 0),
    [cartItems]
  );

  const [shouldShowActivityIndicator, setShouldShowActivityIndicator] = useState(false);

  const getCheckoutClickHandler = useOnCheckoutClick({ setShouldShowActivityIndicator });

  const textFontOverrides = useTextFontOverrides(textFont?.family);
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);

  return (
    <div className={`root ${styles.main}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
            ${buildCssVar('--compare-at-color', compareAtColor, 'var(--text-color-dark)')}
            ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-light)')}
            ${buildCssVar(
            '--button-background-color',
            buttonBackgroundColor,
            'var(--primary-color)'
          )}
            ${buildCssVar('--button-hover-color', buttonHoverColor, 'var(--primary-color)')}
            ${buildCssVar(
            '--button-hover-text-color',
            buttonHoverTextColor,
            'var(--text-color-light)'
          )}
              ${buildCssVar(
            '--button-border-color',
            buttonBorderColor,
            buttonBackgroundColor,
            'var(--primary-color)'
          )}
              ${buildCssVar(
            '--button-hover-border-color',
            buttonHoverBorderColor,
            buttonHoverColor,
            'var(--primary-color)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {shouldShowActivityIndicator && <Spinner />}

      {!hideQuantitySelector && (
        <>
          <div className={styles.products_wrapper}>
            {productsWithOffer &&
              productsWithOffer.map(product => {
                return (
                  <QuantitySelectorProduct
                    key={product.id}
                    product={product}
                    quantity={cartItems.find(el => el.offerId === product.offer?.id).quantity}
                    onQuantityChange={onQuantityChange}
                  />
                );
              })}
          </div>
          {callout && (
            <div className={styles.callout_container}>
              <Callout {...callout} />
            </div>
          )}
          <div className={styles.content_wrapper}>
            <div className={styles.shipping}>
              <FaShippingFast />
              <p>Free Shipping</p>
            </div>
            {!!totalQuantity && (
              <div className={styles.price_details}>
                <p className={styles.saving_txt}>
                  You&apos;re saving <span>{savedPercent}%</span>
                </p>
                <div className={styles.price}>
                  <p className={styles.total_price}>
                    Only <span>${price} </span>
                    {productsWithOffer[0]?.offer?.store?.currency} each
                  </p>
                  <p className={styles.compare_price}>
                    ${compareAtPrice} {productsWithOffer[0]?.offer?.store?.currency}
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {externalLink ? (
        <div className={styles.external_link}>
          <Link href={addQueryParamsToUrl(externalLink, router.query)}>{ctaText}</Link>
        </div>
      ) : (
        <div className={styles.btn_wrapper}>
          <button onClick={getCheckoutClickHandler(cartItems)} disabled={!totalQuantity}>
            {ctaText}
          </button>
        </div>
      )}
    </div>
  );
}

export default QuantitySelector;

QuantitySelector.propTypes = propTypes;
QuantitySelector.defaultProps = defaultProps;

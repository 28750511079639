import React from 'react';
import dynamic from 'next/dynamic';
import discountIcon from 'public/discount.png';
import formatCurrency from 'utils/format-currency';

import Image from 'generic/Image';
import UpsellForm from 'generic/UpsellForm';

import styles from './style.module.css';

const MarkdownText = dynamic(() => import('generic/MarkdownText'));

type Props = {
  heading?: string;
  price?: number;
  compareAtPrice?: number;
  image?: string;
  previewImages?: string[];
  configName?: string;
  variantName?: string;
  offerName?: string;
  description?: string;
  ctaText: string;
  // eslint-disable-next-line no-unused-vars
  onVariantChange?: (e) => void;
  selectedVariantName?: string;
  variantsWithOffer: any[];
  cssVarsText?: string;
};

export default function EnhancedBundleItemDesktop({
  heading,
  price,
  compareAtPrice,
  image,
  previewImages,
  configName,
  description,
  offerName,
  variantName,
  ctaText,
  onVariantChange,
  selectedVariantName,
  variantsWithOffer,
  cssVarsText,
}: Props) {
  const showDiscount = compareAtPrice && price < compareAtPrice;

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{cssVarsText}</style>

      {heading && (
        <div className={styles.bundle_banner}>
          <h3>{heading}</h3>
        </div>
      )}
      <div className={styles.details_container}>
        <div className={styles.product_detail_container}>
          <div className={styles.badge_wrapper}>
            {previewImages?.map((preview: any, i) => (
              <Image className={styles.badge} src={preview?.item} alt={configName} key={i} />
            ))}
          </div>
          <div className={styles.bundle_name_wrapper}>
            <h2 className={styles.product_name}>{variantName || offerName}</h2>
          </div>
        </div>
        <div className={styles.product_image_price_wrapper}>
          <div className={styles.product_image_wrapper}>
            <Image className={styles.product_image} src={image} alt={configName} />
          </div>
          <div className={styles.prices_wrapper}>
            {compareAtPrice && (
              <p className={styles.compare_price}>Retail {formatCurrency(compareAtPrice)}</p>
            )}
            <h2 className={styles.total_price}>{formatCurrency(price)}</h2>
            {showDiscount && (
              <h2 className={styles.saving_price}>Save {formatCurrency(compareAtPrice - price)}</h2>
            )}
          </div>
        </div>

        <div className={styles.bundle_detail_wrapper}>
          <MarkdownText queryParams={null} text={description} />
        </div>

        <div className={styles.price_button_wrapper}>
          <label htmlFor="variants" className={styles.label}>
            <strong>Select Product:</strong>
          </label>
          <select
            name="variants"
            id="variants"
            onChange={onVariantChange}
            className={styles.select}
            value={selectedVariantName}
          >
            {variantsWithOffer.map((item, i) => (
              <option value={item.variantName} key={i}>
                {item.variantName}
              </option>
            ))}
          </select>

          <UpsellForm yesText={ctaText} noText="No thanks" className={styles.product_btn} />

          {showDiscount && (
            <div className={styles.discount_wrapper}>
              <div>
                <Image src={discountIcon} width={22} height={22} alt="Discount" />
              </div>
              <p className={styles.discount_text}>
                You&apos;re saving {((price / compareAtPrice) * 100).toFixed(0)}%*
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

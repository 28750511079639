import { Fragment } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styles from './ValueProposition.theme1.module.css';
import Item, {
  queryTemplate as valuePropositionItemQueryTemplate,
} from './ValuePropositionItem.theme1';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on ValueProposition': [
    'sectionId',
    'tagline',
    'title',
    'text',
    'dividerText',
    {
      items: [
        'collection',
        {
          item: [valuePropositionItemQueryTemplate],
        },
      ],
    },
    'backgroundColor',
    'taglineColor',
    'headingColor',
    'textColor',
    'dividerTextColor',
    'dividerBackgroundColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  sectionId: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  dividerText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  backgroundColor: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  dividerTextColor: PropTypes.string,
  dividerBackgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  sectionId: null,
  title: null,
  text: null,
  backgroundColor: null,
  headingColor: null,
  textColor: null,
  headingFont: null,
  textFont: null,
};

export default function ValueProposition({
  sectionId,
  title,
  text,
  dividerText,
  items,
  backgroundColor,
  headingColor,
  textColor,
  dividerTextColor,
  dividerBackgroundColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div id={sectionId} className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--divider-text-color', dividerTextColor, 'var(--text-color-light)')}
          ${buildCssVar(
            '--divider-background-color',
            dividerBackgroundColor,
            'var(--secondary-color)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.value_proposition}>
        {title && <h1 className={styles.heading}>{title}</h1>}
        {text && (
          <div className={styles.text}>
            <MarkdownText text={text} />{' '}
          </div>
        )}
        <div className={styles.sec_wrapper}>
          {items.map(({ item }, i) => (
            <Fragment key={i}>
              <Item {...item} />
              {dividerText && i !== items.length - 1 && (
                <div className={styles.divider_text}>
                  <h2>{dividerText}</h2>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

ValueProposition.propTypes = propTypes;
ValueProposition.defaultProps = defaultProps;

import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styles from './StoryItem.module.css';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on StoryItem': [
    'title',
    'text',
    'headingColor',
    'textColor',
    'backgroundColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  title: null,
  text: undefined,
  headingColor: null,
  textColor: null,
  backgroundColor: null,
  headingFont: null,
  textFont: null,
};

export default function StoryItem({
  title,
  text,
  headingColor,
  textColor,
  backgroundColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <div className={`root ${styles.card}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {title && <h2 className={styles.founders_card_title}>{title}</h2>}
      {text && <MarkdownText text={text} />}
    </div>
  );
}

StoryItem.propTypes = propTypes;
StoryItem.defaultProps = defaultProps;

import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styles from './MultiProductButton.module.css';
import Image from './Image';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on Product': [
    'id',
    'type',
    'name',
    { image: ['...FilePartsWithMetadata'] },
    'text',
    'headingColor',
    'headingBackgroundColor',
    'textColor',
    'borderColor',
  ],
};

export const productPropTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  image: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  headingColor: PropTypes.string,
  headingBackgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
};

const productOfferPropTypes = {
  offer: PropTypes.shape({ name: PropTypes.string }),
};

const propTypes = {
  onClick: PropTypes.func.isRequired,
  product: PropTypes.shape({ ...productPropTypes, ...productOfferPropTypes }).isRequired,
  isSelected: PropTypes.bool,
};

const defaultProps = {
  isSelected: false,
};

export default function MultiProductButton({ onClick, product, isSelected }) {
  const {
    id,
    type,
    name,
    image,
    text,
    headingColor,
    headingBackgroundColor,
    textColor,
    borderColor,
  } = product;

  useEffect(() => {
    if (type !== 'product') {
      console.error(
        `Incorrect Product type for Multi Product: Products must be of type "Multi Product"`,
        {
          id,
          type,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(() => {
    onClick(product);
  }, [onClick, product]);
  const classNames = [
    'root',
    styles.product_button,
    isSelected ? styles.product_selected : styles.product_unselected,
  ];

  return (
    <button className={classNames.join(' ')} onClick={handleClick}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-light)')}
          ${buildCssVar(
            '--heading-background-color',
            headingBackgroundColor,
            'var(--primary-color)'
          )}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--border-color', borderColor, 'var(--text-color-dark)')}
        }
      `}</style>

      <h4 className={styles.offer_name}>{name || product.offer?.name}</h4>
      <Image src={image} alt={name || product.offer?.name} priority placeholder="blur" />
      <div className={styles.product_text_wrapper}>
        <MarkdownText text={text} />
      </div>
    </button>
  );
}

MultiProductButton.propTypes = propTypes;
MultiProductButton.defaultProps = defaultProps;

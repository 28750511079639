import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import LazyAutoplayVideo from './LazyAutoplayVideo';
import styles from './AutoplayVideoItem.theme1.module.css';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';
import { queryTemplate as bulletQueryTemplate } from './Bullet';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on AutoplayVideoItem': [
    { placeholderImage: ['...FilePartsWithMetadata'] },
    { video: ['...FileParts'] },
    { image: ['...FilePartsWithMetadata'] },
    'mediaAlt',
    'tagline',
    'heading',
    'text',
    {
      bullets: [
        {
          item: [bulletQueryTemplate],
        },
      ],
    },
    'headingColor',
    'headingBackgroundColor',
    'textColor',
    'taglineColor',
    'taglineBackgroundColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  placeholderImage: PropTypes.object,
  video: PropTypes.object,
  mediaAlt: PropTypes.string.isRequired,
  heading: PropTypes.string,
  text: PropTypes.string,
  headingColor: PropTypes.string,
  headingBackgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  placeholderImage: null,
  video: null,
  heading: null,
  text: undefined,
  headingColor: null,
  headingBackgroundColor: null,
  textColor: null,
  headingFont: null,
  textFont: null,
};

export default function AutoplayVideoItem({
  placeholderImage,
  video,
  mediaAlt,
  heading,
  text,
  formattedText,
  headingColor,
  headingBackgroundColor,
  textColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <div className={`root ${styles.box_item}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-light)')}
          ${buildCssVar(
            '--heading-background-color',
            headingBackgroundColor,
            'var(--primary-color)'
          )}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {video && placeholderImage && (
        <LazyAutoplayVideo alt={mediaAlt} placeholderImage={placeholderImage} video={video} />
      )}
      {heading && <h3 className={styles.heading}>{heading}</h3>}
      {!formattedText && text && <MarkdownText text={text} />}
      {formattedText && <div dangerouslySetInnerHTML={{ __html: formattedText }} />}
    </div>
  );
}

AutoplayVideoItem.propTypes = propTypes;
AutoplayVideoItem.defaultProps = defaultProps;

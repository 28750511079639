import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import Image from './Image';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import styles from './AccordionItem.theme1.module.css';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '...on AccordionItem': [
    'title',
    {
      contents: [
        {
          item: [
            {
              '... on AccordionItemContent': [
                'type',
                'text',
                { image: ['...FilePartsWithMetadata'] },
                'imageAlt',
              ],
            },
          ],
        },
      ],
    },
    'textColor',
    'linkTextColor',
    'backgroundColor',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

const propTypes = {
  title: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        type: PropTypes.string.isRequired,
        text: PropTypes.string,
        image: PropTypes.object,
        imageAlt: PropTypes.string,
      }),
    })
  ).isRequired,
  textColor: PropTypes.string,
  linkTextColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  textColor: null,
  linkTextColor: null,
  backgroundColor: null,
  headingFont: null,
  textFont: null,
};

function renderContentItem({ item }, index) {
  switch (item.type) {
    case 'text': {
      return !item.formattedText ? (
        <MarkdownText key={index} text={item.text} />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: item.formattedText }} />
      );
    }

    case 'image': {
      return <Image key={index} src={item.image} alt={item.imageAlt} placeholder="blur" />;
    }

    default:
      return null;
  }
}

export default function AccordionItem({
  title,
  contents,
  textColor,
  linkTextColor,
  backgroundColor,
  headingFont,
  textFont,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container} ${isOpen ? styles.open : styles.closed}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--secondary-color)')}
          ${linkTextColor ? buildCssVar('--link-text-color', linkTextColor) : ''}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <button onClick={handleClick} className={styles.expanding_btn}>
        <header>
          <h1 className={styles.heading}>{title}</h1>
          <h1 className={styles.plus_minus_p}>{isOpen ? '-' : '+'}</h1>
        </header>
      </button>
      <div className={styles.contents}>{contents.map(renderContentItem)}</div>
    </div>
  );
}

AccordionItem.propTypes = propTypes;
AccordionItem.defaultProps = defaultProps;

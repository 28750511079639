const formatMinSecUnits = function (timeLeft) {
  return ` ${timeLeft.minutes.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${timeLeft.seconds.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;
};

const formatHrsMinSecUnits = function (timeLeft) {
  return ` ${timeLeft.hours.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${timeLeft.minutes.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${timeLeft.seconds.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;
};

export { formatHrsMinSecUnits, formatMinSecUnits };

import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import styles from './DetailListModal.module.css';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = {
  linkText: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  title: null,
  text: undefined,
  headingColor: null,
  textColor: null,
  headingFont: null,
  textFont: null,
};

export default function DetailListModal({
  linkText,
  title,
  text,
  headingColor,
  textColor,
  headingFont,
  textFont,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden';
  }, []);
  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    document.body.style.overflow = 'visible';
  }, []);

  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className="root">
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {modalIsOpen && (
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <span onClick={closeModal} className={styles.close}>
              &times;
            </span>
            {title && <h1 className={styles.heading}>{title}</h1>}
            {text && (
              <div className={styles.text}>
                <MarkdownText text={text} />
              </div>
            )}
          </div>
        </div>
      )}
      <button onClick={openModal} className={styles.link}>
        {linkText}
      </button>
    </div>
  );
}

DetailListModal.propTypes = propTypes;
DetailListModal.defaultProps = defaultProps;
